import React, { useState, useRef, useEffect } from 'react';

const emptyFunction = () => {
  /**/
};

interface AudioProps {
  isMuted: boolean;
  isAudioAvailable: boolean;
  toggleMute: (isMuted?: boolean) => void;
  handleSourceChange: (source: string) => void;
  handlePlay: () => void;
  setAudioAvailability: (isAvailable: boolean) => void;
}

const defaultState: AudioProps = {
  isMuted: false,
  isAudioAvailable: false,
  toggleMute: emptyFunction,
  handleSourceChange: emptyFunction,
  handlePlay: emptyFunction,
  setAudioAvailability: emptyFunction
};

export const AudioContext = React.createContext<AudioProps>(defaultState);

export const AudioContextProvider: React.FC = ({ children }) => {
  const playerRef = useRef<any>(null);
  const [src, setSrc] = useState<string>('');
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isAudioAvailable, setIsAudioAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (playerRef) {
      playerRef.current.muted = isMuted;
    }
  }, [isMuted]);

  const handleSourceChange = (src: string) => playerRef && setSrc(src);
  const setAudioAvailability = (isAvailable: boolean) => setIsAudioAvailable(isAvailable);

  const handlePlay = () => {
    if (isMuted) playerRef.current.volume = 0;
    playerRef.current.play();
  };

  const toggleMute = (isMuted?: boolean) => {
    setIsMuted(prev => isMuted ? isMuted : !prev);
  };

  return (
    <AudioContext.Provider
      value={{
        isAudioAvailable,
        isMuted,
        toggleMute,
        handleSourceChange,
        handlePlay,
        setAudioAvailability
      }}
    >
      {children}
      <audio ref={playerRef} src={src} style={{ zIndex: -1, position: 'fixed', left: 0, right: 0, bottom: 0, visibility: 'hidden', opacity: 0 }}></audio>
    </AudioContext.Provider>
  );
};
