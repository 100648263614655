import { FC, useContext, useState } from 'react';
import classes from './AuthModal.module.scss';
import Modal from '../../../components/common/Modal/Modal';
import AuthContext from '../../../store/auth-context';
import LoginFormContainer from '../LoginForm/LoginForm.container';
import RegisterFormContainer from '../RegisterForm/RegisterForm.container';
import ResetPasswordFormContainer from '../ResetPasswordForm/ResetPasswordForm.container';

enum MODAL_FORM {
    LOGIN = 'login',
    REGISTER = 'register',
    RESET_PASSWORD = 'reset',
}

const AuthModal: FC = () => {
    const { handleCloseAuthModal } = useContext(AuthContext);
    const [selectedForm, setSelectedForm] = useState(MODAL_FORM.LOGIN);

    const handleChangeForm = (form: MODAL_FORM) => setSelectedForm(form);

    return (
        <>
        {/* <Modal onClose={handleCloseAuthModal} cssClass={classes.modal} closeButton> */}
            {selectedForm === MODAL_FORM.LOGIN && (
                <LoginFormContainer
                    handleModalRegisterLink={() => handleChangeForm(MODAL_FORM.REGISTER)}
                    handleModalResetPasswordLink={() => handleChangeForm(MODAL_FORM.RESET_PASSWORD)}
                />
            )}
            {/* {selectedForm === MODAL_FORM.REGISTER && (
                <RegisterFormContainer handleModalLoginLink={() => handleChangeForm(MODAL_FORM.LOGIN)} />
            )} */}
            {/* {selectedForm === MODAL_FORM.RESET_PASSWORD && (
             <ResetPasswordFormContainer handleModalRegisterLink={() => handleChangeForm(MODAL_FORM.REGISTER)} />
            )} */}
        {/* </Modal> */}
        </>
    );
};

export default AuthModal;
