export enum Routes {
  MAIN = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/reset-password',
  REGISTER = '/register',
  SKY_GALLERY = '/sky-gallery',
  RECOVER_PASSWORD = '/recover/:user/:code',
  CONFIRM_REGISTER = '/confirm/:userID/:code',
  ROOM = '/room/:slug',
  BOOKMARKS = '/bookmarks',
  CHAT = '/chat',
  SETTINGS = '/settings',
  VALENTINO = '/valentino',
  FERRAGAMO = '/ferragamo',
  DIOR = '/dior'
}
