import { History } from 'history';
import { Mesh, Sprite, Texture } from 'three';
import { createTextureBox, createBendPlane, createPointLight, createHTMLObject, createNFTObject } from './objectCreator';
import {
  isHtmlObjectDefinition,
  isMaterialDefinition,
  isPointLightDefinition,
  isRoomLinkDefinition,
  isTextureBoxDefinition,
  isNFTDefinition,
  isBendPlaneDefinition,
  materialDefinitionToInstance,
  positionObject,
  disposeListeners,
  disposeTextures,
  disposeMeshes,
  createVideoContainer,
} from './helpers';
import { animatedArrowStyles } from './Room.utils'
import { setupPanorama } from './setupPanorama';
import { CSS3DObject } from './vendor/three/CSS3DRenderer';
import { TextureBoxesTypes } from 'src/types/room';

export interface ListenersList {
  object: Mesh | Sprite | HTMLDivElement | CSS3DObject;
  action: string;
  listener: (event: Event | THREE.Event) => void;
}

interface SetupRoomParams {
  container: HTMLDivElement;
  setRoomObjectInfo: (id: any) => void;
  cssRendererClassName: string;
  roomConfiguration: RoomConfiguration;
  history: History;
  debug?: boolean;
}

const setupRoom = ({
  container,
  setRoomObjectInfo,
  cssRendererClassName,
  roomConfiguration,
  history,
  debug = false,
}: SetupRoomParams) => {
  if (
    !roomConfiguration.room_faces ||
    !Array.isArray(roomConfiguration.room_faces) ||
    roomConfiguration.room_faces.length !== 6
  ) {
    return;
  }

  const meshSet = new Set<Mesh | Sprite>([]);
  const textureSet = new Set<Texture>([]);
  const listenersSet = new Set<ListenersList>([]);
  const videoContainer = createVideoContainer(container);
  const { panorama, cssScene, dispose: disposePanorama } = setupPanorama({
    container,
    cssRendererClassName,
    faces: roomConfiguration.room_faces,
    debug,
  });

  const materials = (roomConfiguration.materials ?? [])
    .filter(isMaterialDefinition)
    .map(materialDefinitionToInstance);

  roomConfiguration.textureBoxes?.filter(isTextureBoxDefinition).forEach((definition) => {
    const { id, position } = definition;
    const [object, texture] = createTextureBox(materials, definition);
    panorama.add(positionObject({ object, ...position }));
    if (definition.isClickable) {
      let mouseDown: number;
      const handleMouseDown = (event: any) => {
        mouseDown = event.mouseEvent.clientX;

      }
      const handleMouseUp = (event: any) => {
        if (event.mouseEvent.clientX === mouseDown) {
          if (definition.type === TextureBoxesTypes.LINK && definition.link) {
            history.push(`/room/${definition.link}`);
          } else {
            setRoomObjectInfo({ id });
          }

        } else {
          return false
        }
      }
      object.addEventListener('mousedown', (e) => handleMouseDown(e as MouseEvent));
      object.addEventListener('mouseup', (e) => handleMouseUp(e as MouseEvent));
      listenersSet.add({ object, action: 'mouseup', listener: handleMouseUp });
      listenersSet.add({ object, action: 'mousedown', listener: handleMouseDown });

    }
    meshSet.add(object);
    textureSet.add(texture);
  });

  roomConfiguration.textureBoxes?.filter(isNFTDefinition).forEach((definition) => {
    const { id, position, asset } = definition;
    const object = createNFTObject(id, { position, asset });
    const handleClick = () => {
      setRoomObjectInfo({ id });
    }
    let screenX: number;

    if (!object)
      return;
    const handleTouchStart = (e: TouchEvent) => {
      screenX = e.changedTouches[0].screenX;
    }
    const handleTouchEnd = (e: TouchEvent) => {
      if (e.changedTouches[0].screenX === screenX) {
        setRoomObjectInfo({ id });
      }
    }
    object.element.addEventListener('touchstart', (e: any) => handleTouchStart(e as TouchEvent));
    object.element.addEventListener('touchend', (e: any) => handleTouchEnd(e as TouchEvent));
    object.element.addEventListener('click', handleClick)
    listenersSet.add({ object, action: 'click', listener: handleClick });

    panorama.add(positionObject({ object, ...position }));
    cssScene.add(positionObject({ object, ...position }));

  });

  roomConfiguration.bendPlanes?.filter(isBendPlaneDefinition).forEach((definition) => {
    const { position } = definition;
    const [object, texture] = createBendPlane(materials, definition);
    panorama.add(positionObject({ object, ...position }));

    meshSet.add(object);
    textureSet.add(texture);
  });

  roomConfiguration.roomLinks?.filter(isRoomLinkDefinition).forEach((definition) => {
    const { position, room } = definition;
    const action = 'click';
    const handleLink = () => {
      history.push(`/room/${room.id}`);
    };
    const object = createHTMLObject({
      htmlString: `
            <div class="logoAnimation roomNavigationLink">
            <span class="arrow">
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L1 11L11 21" stroke="white"/>
            </svg>
            </span>
            <span class="ring ring1"></span>
            <span class="ring ring2"></span>
            ${animatedArrowStyles.toString()}
        </div>
        `, width: 1, height: 1, position: { x: position.x, y: position.y }, elementId: `room-link-${room.id}`
    });
    panorama.add(positionObject({ object, ...position }));
    object.element.addEventListener(action, handleLink);
    cssScene.add(positionObject({ object, ...position }));
  });

  roomConfiguration.pointLights?.filter(isPointLightDefinition).forEach((definition) => {
    const object = createPointLight(definition);

    panorama.add(object);
  });

  roomConfiguration.htmlObjects?.filter(isHtmlObjectDefinition).forEach((definition) => {
    const { position } = definition;
    const object = createHTMLObject(definition);
    const video = object.element.children[0];
    video.load();
    video?.addEventListener('click', () => {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    })

    cssScene.add(positionObject({ object, ...position }));
  });

  return {
    dispose: () => {
      disposeListeners(listenersSet);
      disposeTextures(textureSet);
      disposeMeshes(meshSet);
      disposePanorama();

      videoContainer.remove();
    },
  };
};

export { setupRoom };