import { FC, useContext } from 'react';

import RegisterFormContainer from '../components/auth/RegisterForm/RegisterForm.container';

import AuthContext from '../store/auth-context';

const Register: FC = () => {
    const { isLoggedIn } = useContext(AuthContext);

    return <>{!isLoggedIn && <RegisterFormContainer />}</>;
};

export default Register;
