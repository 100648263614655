import { ConfirmAccountData, Credentials, RecoverPasswordData, Register, LoginApi } from '../types/auth-types';
import { Email } from '../types/user-types';
import api from './settings';

export function login(credentials: Credentials): Promise<LoginApi> {
  // assigning to to the field which will be parsed also by lili-api (identifier works for strapi)
  // NOTE: the field name might be different for different branch/environment (e.g it could be username)
  credentials.email = credentials.identifier;

  return api
    .post('/auth/local', credentials)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {

        if (Array.isArray(error.response.data.message)) {
          throw new Error(error.response.data.message[0].messages[0].message);
        }

        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function forgotPassword(email: Email): Promise<boolean> {
  return api
    .post('/auth/forgot-password', email)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {

      if (error.response?.data?.message) {

        if (Array.isArray(error.response.data.message)) {
          throw new Error(error.response.data.message[0].messages[0].message);
        }

        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }

    });
}

export function register(credentials: Register) {
  return api
    .post('/auth/local/register', credentials)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {

      if (error.response?.data?.message) {

        if (Array.isArray(error.response.data.message)) {
          throw new Error(error.response.data.message[0].messages[0].message);
        }

        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function recoverPassword({ passwords, userID, code }: RecoverPasswordData) {
  return api
    .post('/auth/reset-password', {
      code,
      user: userID,
      password: passwords.password,
      passwordConfirmation: passwords.secondPassword,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function validateRecoverCode({ userID, code }: Omit<RecoverPasswordData, 'passwords'>): Promise<boolean> {
  return api
    .post(`/auth/validate-code`, { user: userID, code })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function confirmAccount({ userID, code }: ConfirmAccountData) {
  return api
    .get(`auth/users/confirm/${userID}/${code}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
