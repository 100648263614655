import React, { useEffect, useState } from 'react';
import useHttp from '../hooks/use-http';
import { getConfig } from '../api/config-api';
import { Options } from 'src/types/config-types';

const emptyFunction = () => {
  /**/
};

interface ConfigContext {
  options: Options | null;
  handleChange: (options: Options) => void,
}

const defaultState: ConfigContext = {
  options: null,
  handleChange: emptyFunction,
};

export const ConfigContext = React.createContext(defaultState);

export const ConfigContextProvider: React.FC = ({ children }) => {

  const { data, error, sendRequest } = useHttp(getConfig);
  const [options, setOptions] = useState(defaultState.options);


  useEffect(() => {
    sendRequest();
  }, []);

  useEffect(() => {
    if (data && !error) {
      setOptions(data);
    }
  }, [data, error]);

  const handleChange = (values: Options) => {
    setOptions(values);
  };

  return (
    <ConfigContext.Provider value={{ options, handleChange }}>
      {children}
    </ConfigContext.Provider>
  );

};
