import { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classes from './ResetPasswordForm.module.scss';
import { ResetPasswordFormData } from 'src/types/auth-types';
import Button from '../../common/Button/Button';
import Form from '../../common/Form/Form';
import Spinner from '../../common/Spinner/Spinner';
import Field from '../../common/Field/Field';
import Input from '../../common/Input/Input';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import { emailValidators } from 'src/utils/validators';
import ForwardLink, { Variant, Props as ForwardLinkProps } from '../../common/Link/ForwardLink';
import { Routes } from 'src/types/routes';

interface Props {
    error: string | string[] | null;
    onSubmit: (values: ResetPasswordFormData) => Promise<boolean | string>;
    handleModalRegisterLink?: () => void;
}

const ResetPasswordForm: FC<Props> = ({ error, onSubmit, handleModalRegisterLink }) => {
    const ResetPasswordSchema = Yup.object().shape({
        email: emailValidators,
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: onSubmit,
        validationSchema: ResetPasswordSchema,
    });

    const registerLink: ForwardLinkProps = {
        description: 'Don’t have an account?',
        ...(handleModalRegisterLink
            ? { variant: Variant.BUTTON, onClick: handleModalRegisterLink, centered: true }
            : { variant: Variant.LINK, to: Routes.REGISTER, centered: true }),
    };

    return (
        <Form onSubmit={handleSubmit} title="Forgot your password?">
            <Field
                label="Email address"
                input={<Input placeholder="Enter email address" type="email" {...getFieldProps('email')} />}
                error={errors.email}
                touched={touched.email}
            />

            {error && <ErrorMessage>{error}</ErrorMessage>}

            {isSubmitting ? (
                <Spinner height={40} thickness={10} />
            ) : (
                <Button cssClass={classes['reset-form__submit']} type="submit">
                    Reset password
                </Button>
            )}

            {/* <ForwardLink {...registerLink}>Register here</ForwardLink> */}
        </Form>
    );
};

export default ResetPasswordForm;
