import { FC } from 'react';
import classes from './NotFound.module.scss';
import LinkButton from '../common/LinkButton/LinkButton';
import Title from '../common/Title/Title';
import SectionContainer from '../common/SectionContainer/SectionContainer';

const NotFoundComponent: FC = () => {
    return (
        <SectionContainer>
            <Title title="Page not found" />
            <LinkButton to="/" className={classes['not-found__button']}>
                Back to home
            </LinkButton>
        </SectionContainer>
    );
};

export default NotFoundComponent;
