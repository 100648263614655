import { FC } from 'react';
import classes from './Title.module.scss';

interface Props {
    title?: string;
    divider?: boolean;
}

const Title: FC<Props> = ({ title, children, divider = false }) => {
    return (
        <div className={classes['title']}>
            <h1 className={classes['title__header']}>{title}</h1>
            {divider && <div className={classes['title__divider']} />}
            {children}
        </div>
    );
};

export default Title;
