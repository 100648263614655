import { FC } from 'react';
import SectionContainer from 'src/components/common/SectionContainer/SectionContainer';
import Title from 'src/components/common/Title/Title';
import classes from './InfoContainer.module.scss';

interface Props {
    title: string;
}

const InfoContainer: FC<Props> = ({ title, children }) => {
    return (
        <SectionContainer>
            <Title title={title} />
            <p className={classes['container__text']}>{children}</p>
        </SectionContainer>
    );
};

export default InfoContainer;
