import styles from './Room.module.scss';

type AnimationStrategy = { readonly sequence: any; currentFrame: number; loaded: boolean; readonly assetUrl: string; readonly asset: HTMLImageElement; width: number; height: number; single: { width: number; height: number }; getAsset(): HTMLDivElement; getCurrentOffset(): { x: string; y: string }; animate(): void };

const defaultSequence = {
  count: 120,
    rows: 10,
    columns: 12,
};

const SpriteAnimation = class {
  readonly sequence: any;
  currentFrame: number;
  loaded: boolean;
  readonly assetUrl: string;
  readonly asset: HTMLImageElement;
  width: number;
  height: number;
  single: { width: number; height: number };

  constructor(assetUrl: string) {
    this.assetUrl = assetUrl;
    this.sequence = defaultSequence;
    this.currentFrame = 0;
    this.loaded = false;
    this.asset = new Image();
    this.width = 0;
    this.height = 0;
    this.single = {width: 0, height: 0};
  }

  getAsset() {
    const assetWrapper = document.createElement('div');
    assetWrapper.style.display = "flex";
    const spriteWrapper = document.createElement('div');
    spriteWrapper.style.display = "none";

    this.asset.src = this.assetUrl;
    this.asset.style.display = "block";
    this.asset.style.position = "relative";
    this.asset.addEventListener("load", () => {
      this.width = this.asset.naturalWidth;
      this.height = this.asset.naturalHeight;
      this.single = {
        width: Math.floor(this.width / this.sequence.columns),
        height: Math.floor(this.height / this.sequence.rows)
      };

      spriteWrapper.style.display = "block";
      spriteWrapper.style.width = this.single.width + "px";
      spriteWrapper.style.height = this.single.height + "px";
      spriteWrapper.style.margin = "auto";
      spriteWrapper.style.overflow = "hidden";

      this.loaded = true;
    })

    assetWrapper.appendChild(spriteWrapper);
    spriteWrapper.appendChild(this.asset);

    return assetWrapper;
  }

  getCurrentOffset() {
    const x = -((this.currentFrame % this.sequence.columns) * this.single.width) + "px";
    const y = -(Math.floor(this.currentFrame / this.sequence.columns) * this.single.height) + "px";

    return {x, y};
  }

  animate() {
    setInterval(() => {
      if (!this.loaded) {
        return;
      }

      this.currentFrame = (this.currentFrame + 1) % this.sequence.count;
      const {x, y} = this.getCurrentOffset();
      this.asset.style.left = x;
      this.asset.style.top = y;
    }, 30);
  }
}

const NFT: any = class {
  private readonly id: string;
  private readonly assetUrl: string;
  private readonly animationStrategy: AnimationStrategy;
  private node: HTMLDivElement | undefined;

  constructor(id: string, assetUrl: string) {
    this.assetUrl = assetUrl;
    this.animationStrategy = new SpriteAnimation(this.assetUrl);

    this.id = `nft_${id}`;
  }

  generate() {
    this.node = document.createElement('div');
    this.node.id = this.id;

    this.node.classList.add(styles.nft);
    this.node.classList.add('nft');

    const asset = this.animationStrategy.getAsset();
    this.animationStrategy.animate();

    this.node.appendChild(asset).classList.add('asset');

    return this.node;
  }
}

export { NFT };
