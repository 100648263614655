import { Tokens, JWT } from '../types/tokens';
import jwt_decode from 'jwt-decode';

export const getToken = (tokenName: string) => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        try {
            return localStorage.getItem(tokenName);
        } catch (error) {
            setToken(tokenName, '');
        }
    }
};

export const setToken = (tokenName: string, token: string) => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        try {
            return localStorage.setItem(tokenName, token);
        } catch (error) {
            console.warn(error);
        }
    }
};

export const removeToken = (tokenName: string) => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        try {
            return localStorage.removeItem(tokenName);
        } catch (error) {
            console.warn(error);
        }
    }
};

const isTokenActive = (expirationDate: number, secondsBuffer: number) => {
    const currentDate = Date.now() / 1000;
    const remainingTime = expirationDate - currentDate;

    return remainingTime > secondsBuffer;
};

export const getUserFromStorage = () => {
    try {
        const localStorageToken = getToken(Tokens.LOGIN);

        if (localStorageToken) {
            const { exp, username, email, id, role } = jwt_decode<JWT>(localStorageToken);

            if (isTokenActive(exp, 300) && username) {
                return { username, token: localStorageToken, email, id, role };
            }
        }
    } catch (error) {
        console.warn(error);
    }
};
