import Amplify, { Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';

const awsconfig = {
  'aws_project_region': process.env.REACT_APP_AWS_REGION,
  'aws_cognito_identity_pool_id': process.env.REACT_APP_AWS_COGNITO_IP_ID,
  'aws_cognito_region': process.env.REACT_APP_AWS_COGNITO_REGION,
  'aws_user_pools_id': process.env.REACT_APP_AWS_COGNITO_UP_ID,
  'aws_user_pools_web_client_id': process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID,
  'oauth': {},
};

const setupIoT = async (canPublish = false) => {
  Amplify.configure(awsconfig);
  Auth.configure(awsconfig);

  Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
  }));

  if (canPublish) {
    try {
      const user = await Auth.signIn(
        process.env.REACT_APP_AWS_COGNITO_LOGIN,
        process.env.REACT_APP_AWS_COGNITO_PASSWORD,
      );
      console.log(user);
    } catch (error) {
      console.log('error signing in', error);
    }
  } else {
    const anonymousUser = await Auth.currentCredentials();
    console.log(anonymousUser);
  }
};

export default setupIoT;
