import { Room } from 'src/types/room';
import api from './settings';

export function getRooms(): Promise<Room[]> {
  return api
    .get(`/rooms`, {
      params: {
        'show_in_menu': true,
        '_sort': 'name',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getRoom(id: string): Promise<RoomConfiguration> {
  return api
    .get(`/rooms/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {

      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw { message: error.message, code: error.response.status };
      }
    });
}
