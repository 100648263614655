import { FC } from 'react';
import classes from './Spinner.module.scss';

interface Props {
    cssClass?: string;
    height?: number;
    thickness?: number;
    animationDuration?: number;
}

const Spinner: FC<Props> = ({ cssClass = '', height = 60, thickness = 16, animationDuration = 1 }) => {
    const styles = {
        borderWidth: thickness,
        borderTopWidth: thickness,
        width: height,
        height: height,
        animationDuration: `${animationDuration}s`,
    };

    return (
        <div className={`${classes.root} ${cssClass}`}>
            <div className={classes.spinner} style={styles}></div>
        </div>
    );
};

export default Spinner;
