import { NFTProps } from 'src/types/nft.types';
import api from './settings';

export function getNFTs(): Promise<NFTProps> {
  return api
    .get(`/sky-gallery`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
