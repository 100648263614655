import React, { useState, useEffect, useContext } from 'react';
import { Bookmark } from '../types/bookmark';
import useHttp from '../hooks/use-http';
import { getBookmarks } from '../api/bookmarks-api';
import AuthContext from './auth-context';


const emptyFunction = () => {
  /**/
};

interface BookmarksContext {
  bookmarks: Bookmark[];
  handleBookmarkCreate: (bookmark: Bookmark) => void;
  handleBookmarkDelete: (bookmark: Bookmark) => void;
  handleToggle: (data: BookmarksToggle) => void;
}

interface BookmarksToggle {
  type: 'create' | 'delete';
  bookmark: Bookmark;
}

const defaultState: BookmarksContext = {
  bookmarks: [],
  handleBookmarkCreate: emptyFunction,
  handleBookmarkDelete: emptyFunction,
  handleToggle: emptyFunction,
};

export const BookmarksContext = React.createContext<BookmarksContext>(defaultState);

export const BookmarksContextProvider: React.FC = ({ children }) => {

  const { isLoggedIn } = useContext(AuthContext);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(defaultState.bookmarks);
  const { sendRequest: bookmarksRequest, data: bookmarksData, error: bookmarksError } = useHttp(getBookmarks);

  const handleBookmarkCreate = (bookmark: Bookmark) => {
    setBookmarks([...bookmarks, bookmark]);
  };

  const handleBookmarkDelete = (bookmark: Bookmark) => {
    setBookmarks(bookmarks.filter(({ id }) => id !== bookmark.id));

  };

  const handleToggle = (data: BookmarksToggle) => {

    const { type, bookmark } = data;

    switch (type) {
      case 'create':
        return handleBookmarkCreate(bookmark);
      case 'delete':
        return handleBookmarkDelete(bookmark);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      bookmarksRequest();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (bookmarksData && !bookmarksError) {
      setBookmarks(bookmarksData);
    }
  }, [bookmarksData, bookmarksError]);

  return (
    <BookmarksContext.Provider value={{
      handleBookmarkCreate,
      handleBookmarkDelete,
      handleToggle,
      bookmarks,
    }}>
      {children}
    </BookmarksContext.Provider>
  );
};
