import { FC, useEffect, useContext } from 'react';
import useHttp, { RequestStatus } from 'src/hooks/use-http';
import { deleteMessage } from 'src/api/chat-api';
import styles from '../Chat.module.scss';
import PubSub from '@aws-amplify/pubsub';
import { ChatContext } from '../../../store/chat-context';

interface Props {
  id: number;
}

const RemoveMessage: FC<Props> = ({ id }) => {

  const { topic } = useContext(ChatContext);
  const { sendRequest, status, data } = useHttp(deleteMessage);

  const handleDelete = () => {
    return sendRequest(id);
  };

  useEffect(() => {
    if (status === RequestStatus.completed && data) {

      const emit = async () => {
        await PubSub.publish(topic, { 'action': 'delete', data });
      };

      emit();

    }
  }, [data, status]);

  return <button className={styles['chat-message__btn-delete']} onClick={handleDelete}>Delete</button>;
};

export default RemoveMessage;
