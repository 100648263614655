import api from './settings';
import { NewMessage } from 'src/types/chat-types';

export function getLastMessages() {
  return api.get('/messages/', {
    params: {
      '_sort': 'id:DESC',
      '_limit': 30,
    },
  })
    .then(res => {
      return res.data.reverse();
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function sendMessage(message: NewMessage) {
  return api.post('/messages/', message)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteMessage(id: number) {
  return api.delete(`/messages/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(error => {

      console.error('Cannot remove message', error);

      throw new Error(error.message);
    });
}
