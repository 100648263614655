import React, { useState, useEffect, useContext, useRef } from 'react';
import { Message } from '../types/chat-types';
import useHttp from '../hooks/use-http';
import { getLastMessages } from '../api/chat-api';
import PubSub from '@aws-amplify/pubsub';
import AuthContext from './auth-context';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import notificationSound from '../assets/sound/notification.mp3';

const emptyFunction = () => {
  /**/
};

interface ChatContext {
  messages: Message[],
  topic: string;
  handleNewMessage: (message: Message) => void,
  handleDeleteMessage: (message: Message) => void,
}

const defaultState: ChatContext = {
  messages: [],
  topic: '',
  handleNewMessage: emptyFunction,
  handleDeleteMessage: emptyFunction,
};

export const ChatContext = React.createContext<ChatContext>(defaultState);

export const ChatContextProvider: React.FC = ({ children }) => {

  const [messages, setMessages] = useState<Message[]>(defaultState.messages);
  const { sendRequest, data, error } = useHttp(getLastMessages);
  const { user } = useContext(AuthContext);
  const topic = `${process.env.REACT_APP_ENVIRONMENT}/chat`;

  const notificationRef = useRef<any>(true);

  useEffect(() => {
    sendRequest();
  }, []);

  useEffect(() => {
    if (data && !error) {
      setMessages(data);
    }
  }, [data, error]);

  useEffect(() => {

    PubSub.subscribe(topic).subscribe({
      next: data => {
        const { value } = data;

        if (value.action === 'publish') {
          handleNewMessage(value.data);
        }

        if (value.action === 'delete') {
          handleDeleteMessage(value.data);
        }
      },
      error: error => console.error(error),
    });

  }, []);

  const handleNewMessage = (message: Message) => {

    if (localStorage.getItem('gg')) {
      if (message.user.username !== user.username) {
        notificationRef.current?.play();
      }
    }

    return setMessages(messages => [...messages, message]);
  };

  const handleDeleteMessage = (message: Message) => {

    return setMessages(messages => messages?.filter(msg => msg.id !== message.id));
  };

  return (
    <ChatContext.Provider value={{
      messages,
      topic,
      handleNewMessage,
      handleDeleteMessage,
    }}>
      {children}
      <audio style={{ display: 'none' }} ref={notificationRef}>
        <source src={notificationSound} type={`audio/mpeg`} />
        <source src={notificationSound} type={`audio/ogg`} />
      </audio>
    </ChatContext.Provider>
  );

};
