import React, { useContext, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import SettingsForm from './SettingsForm';
import { toast as notification } from 'react-toastify';
import { ConfigContext } from 'src/store/config-context';
import useHttp, { RequestStatus } from 'src/hooks/use-http';
import { setConfig } from 'src/api/config-api';
import { Options } from 'src/types/config-types';


const SettingsPage: React.FC = () => {

  const { options, handleChange } = useContext(ConfigContext);
  const { sendRequest, data, status } = useHttp(setConfig);

  const handleSubmit = (values: Options) => {
    return sendRequest(values);
  };

  useEffect(() => {
    if (data && status === RequestStatus.completed) {
      notification.info('Saved.');
      handleChange(data);
    }
  }, [data, status]);

  const form = useMemo(() => options && (
    <SettingsForm options={options} onSubmit={handleSubmit} />
  ), [options]);

  return (
    <div className={styles['settings']}>
      <div className={styles['settings-title']}>
        <span className={styles['settings-title__text']}>Settings</span>
      </div>

      {form}

    </div>
  );

};

export default SettingsPage;
