import * as Yup from 'yup';

export const Validators = {
  patterns: {
    containOneLowercaseLetter: /^(?=.*?[a-z]).{1,}$/,
    containOneUppercaseLetter: /^(?=.*?[A-Z]).{1,}$/,
    containOneNumber: /^(?=.*?[0-9]).{1,}$/,
  },
  messages: {
    password: {
      required: 'Password is required!',
      minLength: (length: number) => `Password must contain at least ${length} characters!`,
      maxLength: (length: number) => `Password must be shorter than ${length} characters!`,
      containOneLowercaseLetter: 'Password must contain at least one lowercase character!',
      containOneUppercaseLetter: 'Password must contain at least one uppercase character!',
      containOneNumber: 'Password must contain at least one number character!',
      secondPasswordMarch: 'Passwords must match',
    },
    mail: {
      required: 'Email address is required',
      invalid: 'This is not a valid email address',
      maxLength: (length: number) => `Email must be shorter than ${length} characters!`,
    },
    username: {
      required: 'Name is required',
      minLength: (length: number) => `Name must contain at least ${length} characters!`,
      maxLength: (length: number) => `Name must be shorter than ${length} characters!`,
    },
    user: {
      required: 'User is required',
      invalid: 'This is not a valid user',
      maxLength: (length: number) => `User must be shorter than ${length} characters!`,
    },
    data_processing: {
      required: 'Data Processing consent is required',
    },
    marketing: {},
  },
};

export const usernameValidators = Yup.string()
  .min(3, Validators.messages.username.minLength(3))
  .max(50, Validators.messages.username.maxLength(50))
  .required(Validators.messages.username.required);

export const emailValidators = Yup.string()
  .email(Validators.messages.mail.invalid)
  .max(50, Validators.messages.mail.maxLength(50))
  .required(Validators.messages.mail.required);

export const userValidators = Yup.string()
  .email(Validators.messages.user.invalid)
  .max(50, Validators.messages.user.maxLength(50))
  .required(Validators.messages.user.required);

export const passwordValidators = Yup.string()
  .required(Validators.messages.password.required)
  .min(8, Validators.messages.password.minLength(8))
  .max(50, Validators.messages.password.maxLength(50))
  .matches(Validators.patterns.containOneUppercaseLetter, Validators.messages.password.containOneUppercaseLetter)
  .matches(Validators.patterns.containOneLowercaseLetter, Validators.messages.password.containOneLowercaseLetter)
  .matches(Validators.patterns.containOneNumber, Validators.messages.password.containOneNumber);

export const secondPasswordValidators = (fieldReference: string) => {
  const matchValidator = Yup.string().oneOf(
    [Yup.ref(fieldReference), null],
    Validators.messages.password.secondPasswordMarch,
  );

  return matchValidator.concat(passwordValidators);
};

export const dataProcessingValidators = Yup.bool().oneOf([true], Validators.messages.data_processing.required);

export const marketingValidators = Yup.bool();
