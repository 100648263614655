import { FC } from 'react';
import { useParams } from 'react-router-dom';
import RecoveryPasswordFormContainer from 'src/components/auth/RecoverPasswordForm/RecoveryPasswordForm.container';
interface Params {
    user: string;
    code: string;
}

const RecoverPassword: FC = () => {
    const { user, code } = useParams<Params>();
    return <RecoveryPasswordFormContainer code={code} userID={user} />;
};

export default RecoverPassword;
