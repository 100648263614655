import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from '../Button/Button.module.scss';

const LinkButton: FC<LinkProps> = ({ children, ...rest }) => {
    const classes = `${styles.button} ${rest.className} `;

    return (
        <Link {...rest} className={classes}>
            {children}
        </Link>
    );
};

export default LinkButton;
