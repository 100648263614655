import React, { FC } from 'react';
import classes from './Button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    cssClass?: string;
}

const Button: FC<Props> = ({ children, cssClass = '', loading, ...props }) => {
    return (
        <button className={`${classes.button} ${cssClass}`} {...props}>
            {loading ? 'loading...' : children}
        </button>
    );
};

export default Button;
