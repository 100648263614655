import { FC } from 'react';
import styles from './SectionContainer.module.scss';

interface Props {
    cssClass?: string;
}

const SectionContainer: FC<Props> = ({ children, cssClass = '' }) => {
    const classes = `${styles.container} ${cssClass} `;

    return <div className={classes}>{children}</div>;
};

export default SectionContainer;
