import React, { FunctionComponent } from 'react';

import { MODAL_NAVIGATION_DIRECTIONS } from 'src/utils/modal';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg';

import styles from './DialogDefaultNavigation.module.scss';

interface DialogDefaultNavigationProps {
  onClose?: () => void;
  handleSlideChange?: (direction: string) => void;
}

const DialogDefaultNavigation: FunctionComponent<DialogDefaultNavigationProps> = ({
  onClose,
  handleSlideChange
}) => (
  <div className={styles.Navigation}>
    <div onClick={() => handleSlideChange && handleSlideChange(MODAL_NAVIGATION_DIRECTIONS.PREV)}>
      <ArrowLeft />
    </div>
    <span onClick={onClose}>Close</span>
    <div onClick={() => handleSlideChange && handleSlideChange(MODAL_NAVIGATION_DIRECTIONS.NEXT)}>
      <ArrowRight />
    </div>
  </div>
);

export default DialogDefaultNavigation;
