import { useState, FC } from 'react';
import { useFormik } from 'formik';
import { Credentials, LoginApi } from 'src/types/auth-types';
import * as Yup from 'yup';
import classes from './LoginForm.module.scss';
import Form from '../../common/Form/Form';
import Spinner from '../../common/Spinner/Spinner';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import Field from '../../common/Field/Field';
import InputPassword from '../../common/InputPassword/InputPassword';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import { userValidators } from 'src/utils/validators';
import ForwardLink, { Variant, Props as ForwardLinkProps } from '../../common/Link/ForwardLink';
import { Routes } from 'src/types/routes';
import Title from 'src/components/common/Title/Title';

interface Props {
    error: string | string[] | null;
    onSubmit: (values: Credentials) => Promise<string | LoginApi>;
    handleModalRegisterLink?: () => void;
    handleModalResetPasswordLink?: () => void;
}

const LoginForm: FC<Props> = ({ error, onSubmit, handleModalRegisterLink, handleModalResetPasswordLink }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const SignInSchema = Yup.object().shape({
        identifier: userValidators,
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = useFormik({
        initialValues: {
            identifier: '',
            password: '',
        },
        onSubmit: onSubmit,
        validationSchema: SignInSchema,
    });

    const changePasswordShow = () => setShowPassword((state) => !state);

    const forgotPasswordLink: ForwardLinkProps = {
        ...(handleModalResetPasswordLink
            ? { variant: Variant.BUTTON, onClick: handleModalResetPasswordLink }
            : { variant: Variant.LINK, to: Routes.FORGOT_PASSWORD }),
    };

    const registerLink: ForwardLinkProps = {
        description: '- or -',
        ...(handleModalRegisterLink
            ? { variant: Variant.BUTTON, onClick: handleModalRegisterLink, centered: true }
            : { variant: Variant.LINK, to: Routes.REGISTER, centered: true }),
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Title title='Welcome to the' />
            <Title title='House of Dreams' />
            <p className={classes.description}>The House is temporarily closed to the general public. Please come back soon to explore a unique virtual interactive experience celebrating L’Officiel’s rich history.</p>
            <Field
                label="User"
                labelFor="email"
                input={<Input
                    // placeholder="Enter email address"
                    type="text"
                    {...getFieldProps('identifier')}
                    />}
                error={errors.identifier}
                touched={touched.identifier}
            />
            <Field
                label="Password"
                labelFor="password"
                input={
                    <InputPassword
                        {...getFieldProps('password')}
                        showPassword={showPassword}
                        onShowPassword={changePasswordShow}
                    />
                }
                error={errors.password}
                touched={touched.password}
            ></Field>

      {/* <ForwardLink {...forgotPasswordLink}>Forgot password?</ForwardLink> */}
      {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}

      {isSubmitting ? (
        <Spinner height={40} thickness={10} />
      ) : (
        <Button cssClass={classes['login-form__submit']} type='submit'>
          Log in
        </Button>
      )}
            {/* <ForwardLink {...registerLink}>Create An Account</ForwardLink> */}
        </Form>
    );
};

export default LoginForm;
