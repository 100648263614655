import { FunctionComponent } from 'react';
import DialogDefaultNavigation from '../../../DialogDefaultNavigation/DialogDefaultNavigation';
import { ContentProps } from '../../DialogComponent';
import styles from './IFrameContent.module.scss';

const IFrameContent: FunctionComponent<ContentProps> = ({
  onClose,
  handleSlideChange,
  iframe,
}) => (
  <div className={styles.Container}>
    <iframe
      src={iframe}
      width='100%'
      height='100%'
    />
    <DialogDefaultNavigation handleSlideChange={handleSlideChange} onClose={onClose} />
  </div>
);

export default IFrameContent;
