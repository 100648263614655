import React, { FC, useContext } from 'react';
import AuthContext from 'src/store/auth-context';
import classes from './AnimatedLogo.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  handleClick: () => void;
  cssClass?: string;
  handleSkip: () => void;
}

const AnimatedLogo: FC<Props> = ({ handleClick, handleSkip, children, cssClass = '', loading, ...props }) => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <div
      className={`${classes.buttonContainer} ${cssClass}`}
      // onClick={handleClick}
    >
      <div className={classes.logoAnimation} onClick={handleClick}>
        <span className={classes.titleEnter}>Enter</span>
      <span className={classes.logo}></span>
      <span className={`${classes.ring} ${classes.ring1}`}></span>
      <span className={`${classes.ring} ${classes.ring2}`}></span>
      </div>
      
      {/* {!isLoggedIn &&  */}
      <div className={classes.skip} onClick={handleSkip}>SKIP INTRO</div>
      {/* // } */}
    </div>
  );
};

export default AnimatedLogo;