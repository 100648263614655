export interface Room {
  id: string;
  name: string;
  config: RoomConfiguration;
}

export enum TextureBoxesTypes {
  IMAGE = 'image',
  TEXT = 'text',
  VIDEO = 'video',
  LINK = 'link',
  DOCUMENT = 'document',
  IFRAME = 'iframe',
  AUDIO = 'audio',
  SKY_GALLERY = 'skygallery'
}

