import React, { useState } from 'react';

const emptyFunction = () => {
    /**/
};

interface NavState {
    open: boolean;
    roomName: string;
    selectedNFT: number;
    handleOpen: () => void;
    handleClose: () => void;
    handleToggle: () => void;
    handleRoomName: (roomName: string) => void;
    handleNFTChange: (nftNo: number) => void;
}

const defaultState: NavState = {
    open: false,
    roomName: '',
    selectedNFT: 0,
    handleOpen: emptyFunction,
    handleClose: emptyFunction,
    handleToggle: emptyFunction,
    handleRoomName: emptyFunction,
    handleNFTChange: emptyFunction
};

export const NavContext = React.createContext<NavState>(defaultState);

export const NavContextProvider: React.FC = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [roomName, setRoomName] = useState<string>('');
    const [selectedNFT, setSelectedNFT] = useState<number>(0);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((state) => !state);
    };

    const handleRoomName = (roomName: string) => setRoomName(roomName);

    const handleNFTChange = (nftNo: number) => setSelectedNFT(nftNo);

    return (
        <NavContext.Provider
            value={{
                open,
                roomName,
                selectedNFT,
                handleOpen,
                handleClose,
                handleToggle,
                handleRoomName,
                handleNFTChange
            }}
        >
            {children}
        </NavContext.Provider>
    );
};
