import { FC, useContext } from 'react';
import { v4 } from 'uuid';
import { NavContext } from 'src/store/nav-context';
import { AudioContext } from 'src/store/audio-context';
import { stringToSlug } from 'src/utils/common';
import classes from './LogoHeader.module.scss';
import logo from './header_logo.svg';

import { ReactComponent as PlayIcon } from 'src/assets/icons/playIcon.svg';
import { Link } from 'react-router-dom';

const LogoHeader: FC = () => {
  const { roomName, open } = useContext(NavContext);
  const { handlePlay, isAudioAvailable } = useContext(AudioContext);
  const roomSlug = roomName ? stringToSlug(roomName) : v4();

  const splitRoomName = (name: string) => {
    const splitted = name.split('');

    const mappedLetters = splitted.map((letter: string, index: number) => <span key={index} className={classes.letter} style={{ animationDelay: `${index * 50}ms` }}>{letter === ' ' ? '\xa0' : letter}</span>);

    return mappedLetters;
  }

  return (
    <div className={classes.header}>
      <Link to={`/room/20`} className={classes.logo}>
        <img src={logo} className={classes.image} alt='logo' />
      </Link>
      <h1 key={roomSlug} className={`${classes.roomName} ${open && classes.invisible} ${isAudioAvailable && classes.withIcon}`}>
        <strong>{splitRoomName(roomName)}</strong>
        {isAudioAvailable && <span onClick={handlePlay}>
          <PlayIcon />
        </span>}
      </h1>
    </div>
  )
}

export default LogoHeader;
