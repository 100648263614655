import {
  BoxGeometry,
  BufferGeometry,
  InterleavedBufferAttribute,
  Mesh,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  PlaneBufferGeometry,
  PointLight,
  Sprite,
  SpriteMaterial,
  Texture,
  TextureLoader,
  Vector2,
  VideoTexture,
} from 'three';
import {NFT} from "./NFT";
import arrowIcon from './assets/icons/arrow-up.png';
import { CSS3DObject } from './vendor/three/CSS3DRenderer';

type MeshTuple = [Mesh | Sprite, Texture];

type CreateTextureOverload = {
  (url: string): Texture
  ({ mime, url }: Asset): Texture
}

const createTexture: CreateTextureOverload = (param: string | Asset): Texture => {

  if (typeof param === 'string') {
    return new TextureLoader().load(param);
  }

  const { mime, url } = param;

  const type = mime.split('/')[0];

  switch (type) {
    case 'video':
      return createVideoTexture(url);
    default:
      return new TextureLoader().load(url);
  }

};

// Memory Leak possible - Testing is required
const createVideoTexture = (url: string): Texture => {
  const video = document.createElement('video');
  video.muted = true;
  video.setAttribute('crossorigin', 'anonymous');
  video.setAttribute('src', url);
  video.setAttribute('autoplay', '');
  video.setAttribute('playsinline', '');

  const container = document.getElementById('VideoCache');
  container?.appendChild(video);

  video.load();

  return new VideoTexture(video);
};

const createGeometry = (width: number, height: number) => {
  return new BoxGeometry(width, height, 1);
};

const createBendGeometry = (width: number, height: number, bend: number) => {
  const geometry = new PlaneBufferGeometry(width, height, 35, 35);
  bendPlane(geometry, bend);

  return geometry;
};

const bendPlane = (geometry: PlaneBufferGeometry, bendDepth: number) => {
  const parameters = geometry.parameters;
  const halfWidth = parameters.width * 0.5;

  const a = new Vector2(-halfWidth, 0);
  const b = new Vector2(0, bendDepth);
  const c = new Vector2(halfWidth, 0);

  const ab = new Vector2().subVectors(a, b);
  const bc = new Vector2().subVectors(b, c);
  const ac = new Vector2().subVectors(a, c);

  const r = (ab.length() * bc.length() * ac.length()) / (2 * Math.abs(ab.cross(ac)));

  const center = new Vector2(0, bendDepth - r);
  const baseV = new Vector2().subVectors(a, center);
  const baseAngle = baseV.angle() - Math.PI * 0.5;
  const arc = baseAngle * 2;

  const uv = geometry.attributes.uv;
  const position = geometry.attributes.position;
  const mainV = new Vector2();
  for (let i = 0; i < uv.count; i++) {
    const uvRatio = 1 - uv.getX(i);
    const y = position.getY(i);
    mainV.copy(c).rotateAround(center, arc * uvRatio);
    position.setXYZ(i, mainV.x, y, -mainV.y);
  }

  if (position instanceof InterleavedBufferAttribute) {
    position.data.needsUpdate = true;
  } else {
    position.needsUpdate = true;
  }
};

const createMesh = (
  material: MeshStandardMaterial | MeshPhysicalMaterial | MeshBasicMaterial,
  texture: Texture,
  geometry: BufferGeometry,
) => {
  const materialClone = material.clone();
  materialClone.map = texture;

  return new Mesh(geometry, materialClone);
};

const createTextureBox = (
  materials: (MaterialInstance | null)[],
  { asset, name }: TextureBoxDefinition,
): MeshTuple => {

  const material = materials.find((material) => material?.name === name)?.material ?? new MeshBasicMaterial();
  material.transparent = true;
  const texture = createTexture(asset);
  const geometry = createGeometry(asset.width, asset.height);

  return [createMesh(material, texture, geometry), texture];
};

const createBendPlane = (
  materials: (MaterialInstance | null)[],
  { asset, name, bend }: BendPlanesDefinition,
): MeshTuple => {
  const material = materials.find((material) => material?.name === name)?.material ?? new MeshBasicMaterial();
  const texture = createTexture(asset);
  // Strapi couldn't calculate video dimensions, so I had to hardcode it for the demo
  const geometry = createBendGeometry(320, 480, bend);

  return [createMesh(material, texture, geometry), texture];
};

const createPointLight = ({ color, intensity, position }: PointLightDefinition) => {
  const pointLight = new PointLight(color, intensity);
  pointLight.position.set(position.x ?? 0, position.y ?? 0, position.z ?? 0);
  return pointLight;
};

const createRoomLink = ({ scale = 300, icon = arrowIcon }: RoomLinkDefinition): MeshTuple => {
  const texture = createTexture(icon);
  const material = new SpriteMaterial({ map: texture, depthTest: false });

  const infospot = new Sprite(material);
  infospot.scale.set(scale, scale, 1);
  infospot.renderOrder = 1;

  return [infospot, texture];
};

const createHTMLObject = ({ htmlString, width, height, elementId = 'VidContainer' }: HtmlObjectDefinition) => {
  const element = document.createElement('div');
  element.id = elementId;
  element.style.width = `${width}px`;
  element.style.height = `${height}px`;
  element.innerHTML = htmlString;
  return new CSS3DObject(element);
};

const createNFTObject = (id: number, definition: Partial<TextureBoxDefinition>) => {
  if (!definition?.asset?.url)
    return ;
  const nft = new NFT(id, definition.asset.url);
  const node = nft.generate();
  node.id = `nft_${id}`;

  return new CSS3DObject(node);
};

export { createTextureBox, createBendPlane, createPointLight, createRoomLink, createHTMLObject, createNFTObject };