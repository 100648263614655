export const AVAILABLE_MODAL_CONTENT_TYPE = {
  IMAGE: 'image',
  TEXT: 'text',
  VIDEO: 'video',
  AUDIO: 'audio',
  GALLERY: 'skygallery',
  DOCUMENT: 'document',
  IFRAME: 'iframe'
};

export const MODAL_NAVIGATION_DIRECTIONS = {
  NEXT: 'NEXT',
  PREV: 'PREV'
};
