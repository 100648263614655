import React, { useState, useEffect, FC } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getRoom } from 'src/api/room-api';
import useHttp from 'src/hooks/use-http';
import { Room } from './Room';
import RoomMeta from './Room.meta';

interface Props {
  roomNumber?: string;
}

const RoomContainer: FC<Props> = ({ roomNumber }) => {
  const { slug } = useParams<{ slug: string }>();
  const { sendRequest: roomRequest, data: roomData, error: roomError, code: statusCode } = useHttp(getRoom);

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const debug = query.get('debug') === 'true';

  const [roomConfiguration, setRoomConfiguration] = useState<RoomConfiguration>();

  useEffect(() => {
    roomRequest(roomNumber || slug);
  }, [slug]);

  useEffect(() => {
    if (roomError && statusCode === 404) {
      return history.push(`/room/20/`); // 20 is the Lobby Room
    }
  }, [roomError]);

  useEffect(() => {
    if (roomData && !roomError) {
      if (roomData) {
        setRoomConfiguration(roomData);
      }
    }
  }, [roomData, roomError]);


  return (
    <>
      {roomData?.seo && (
        <RoomMeta data={roomData.seo} />
      )}
      <Room roomConfiguration={roomConfiguration} debug={debug} />
    </>);
};

export { RoomContainer };
