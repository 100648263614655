import { FunctionComponent, useEffect, useState, useRef, useContext } from 'react';

import { AudioContext } from 'src/store/audio-context';
import { ContentProps } from '../../DialogComponent';
import DialogExtendedNavigation from '../../../DialogExtendedNavigation/DialogExtendedNavigation';
import { ReactComponent as PlayIcon } from 'src/assets/icons/play.svg';
import { ReactComponent as PauseIcon } from 'src/assets/icons/pause.svg';
import { calculateVideoProgress } from './VideoContent.utils';

import styles from './VideoContent.module.scss';

const VideoContent: FunctionComponent<ContentProps> = ({ handleSlideChange, onClose, creditInfo, ...rest }) => {
  const { isMuted } = useContext(AudioContext);
  const videoRef = useRef<any>(null);
  const [counter, setCounter] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (videoRef && isLoaded) {
      const { current: video } = videoRef;
      video.volume = 0.3;
      video.muted = isMuted;
      video.play();
      setIsPlaying(true);
      video.addEventListener('ended', () => {
        setIsPlaying(false);
        setCounter(0);
        setDuration(0);
      });
    }
  }, [videoRef, isLoaded]);

  useEffect(() => {
    /*eslint-disable */
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      if (videoRef && isPlaying) {
        const currentTime = calculateVideoProgress(videoRef.current) as any;
        setDuration(currentTime);
      }
      setCounter(prev => prev + 1);
    }, 100);
    if (duration >= 100) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
    /*eslint-enable */
  }, [counter]);

  useEffect(() => {
    if (videoRef) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  const togglePlay = () => setIsPlaying(prev => {
    if (videoRef) {
      const { current: video } = videoRef;
      prev ? video.pause() : video.play();
    }
    return !prev;
  });

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.VideoWrapper}>
          <video
            src={rest?.additionalSource?.url}
            autoPlay
            preload='auto'
            ref={videoRef}
            playsInline
            style={{ pointerEvents: 'none' }}
            onLoadedData={() => setIsLoaded(true)}>
          </video>
        </div>
        <div className={styles.ControlsWrapper}>
          <div className={styles.IconBox} onClick={togglePlay}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>
          <div className={styles.Timeline}>
            <span style={{ width: `${duration}%` }}/>
          </div>
          <span className={styles.CloseButton} onClick={onClose}>Close</span>
        </div>
      </div>
      <DialogExtendedNavigation creditInfo={creditInfo} handleSlideChange={handleSlideChange} />
    </div>
  )
};

export default VideoContent;
