import { FC } from 'react';
import classes from './Input.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    touched?: boolean;
    labelFor?: string;
}

const Input: FC<Props> = ({ touched, error, labelFor, className, ...props }) => {
    const invalidClass = error && touched ? classes['input--error'] : '';
    const validClass = !error && touched ? classes['input--correct'] : '';

    return <input {...props} id={labelFor} className={`${className} ${classes.input} ${invalidClass} ${validClass}`} />;
};

export default Input;
