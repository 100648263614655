export const animatedArrowStyles = `
  <style>
    .logoAnimation {
      display: block;
      color: white;
      text-transform: uppercase;
      height: 50px;
      width: 50px;
      cursor: pointer;
    }
    .arrow {
      position: absolute;
      left: -7px;
      top: 18px;
      transform: rotate(90deg);
    }
    @keyframes opacity {
      0% {
          opacity: 0;
      }
      30% {
          opacity: 1;
      }
      70% {
          opacity: 1;
      }
      100% {
          opacity: 0;
      }
    }
    .logoAnimation .ring {
      position: absolute;
      display: block;
      height: 61px;
      width: 61px;
      top: 2px;
      left: -31px;
      border-radius: 65px;
      -webkit-animation: rotate 8s 3s infinite linear, fadein-1 2s 1s ease-in normal forwards;
      animation: rotate 8s 3s infinite linear, fadein-1 2s 1s ease-in normal forwards;
      pointer-events: none;
    }
    .logoAnimation .ring.ring2 {
      left: -31px;
      -webkit-animation: rotate 6s 3s infinite linear, fadein-2 2s 1s ease-in normal forwards;
      animation: rotate 6s 3s infinite linear, fadein-2 2s 1s ease-in normal forwards;
    }
    @keyframes rotate {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      50% {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
    }
    @keyframes fadein-1 {
      0% {
          left: -31px;
      }
      100% {
        left: -31px;
      }
    }
    @keyframes fadein-2 {
      0% {
        left: -31px;
      }
      100% {
        left: -31px;
      }
    }
    .logoAnimation .ring::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 65px;
      padding: 1px;
      background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: source-out;
      mask-composite: exclude;
      transform: translateX(10px);
      transition: transform 0.4s ease-in-out;
      opacity: 0;
      -webkit-animation: opacity-2 2s 1s ease-in-out 1 normal forwards;
      animation: opacity-2 2s 1s ease-in-out 1 normal forwards;
    }
    @keyframes opacity-2 {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
    }
    .logoAnimation .ring.ring2::before {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    .logoAnimation:hover .ring::before {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  </style>
`
