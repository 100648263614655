import { FC, useContext, useMemo } from 'react';

import classes from './Bookmarks.module.scss';
import AuthContext from 'src/store/auth-context';
import InfoContainer from '../common/InfoContainer/InfoContainer';
import SectionContainer from '../common/SectionContainer/SectionContainer';
import Title from '../common/Title/Title';
import Button from '../common/Button/Button';
import BookmarkPreview from './BookmarkPreview';
import { Bookmark } from '../../types/bookmark';
import { BookmarksContext } from '../../store/bookmarks-context';

const Bookmarks: FC = () => {
  const { isLoggedIn, handleOpenAuthModal } = useContext(AuthContext);
  const { bookmarks } = useContext(BookmarksContext);

  const renderBookmarks = useMemo(() => {

    return bookmarks.map((bookmark: Bookmark) => {
      return (
        <BookmarkPreview key={bookmark.id} data={bookmark} />
      );
    });
  }, [bookmarks]);

  return isLoggedIn ? (
    <div className={classes['bookmarks']}>
      <InfoContainer title='Rooms'>Your favorites rooms</InfoContainer>
      {renderBookmarks}
      <InfoContainer title='NFTs'>Your favorites NFTs</InfoContainer>
    </div>
  ) : (
    <SectionContainer>
      <Title title='You must be logged in to see your bookmarks' />
      <Button cssClass={classes['bookmarks__button']} onClick={handleOpenAuthModal}>
        Log in
      </Button>
    </SectionContainer>
  );
};

export default Bookmarks;
