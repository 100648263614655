import React, { FC, useContext, useEffect } from 'react';
import PubSub from '@aws-amplify/pubsub';
import styles from '../Chat.module.scss';
import { ChatContext } from 'src/store/chat-context';
import ChatForm from '../ChatForm/ChatForm';
import ChatList from '../ChatList/ChatList';
import { NewMessage } from 'src/types/chat-types';
import useHttp, { RequestStatus } from 'src/hooks/use-http';
import { sendMessage } from 'src/api/chat-api';

const ChatComponent: FC = () => {

  const { messages, topic } = useContext(ChatContext);
  const { sendRequest, data, status } = useHttp(sendMessage);

  const handleSubmit = (message: NewMessage) => {
    return sendRequest(message);
  };

  useEffect(() => {
    if (status === RequestStatus.completed && data) {

      const emit = async () => {
        await PubSub.publish(topic, { 'action': 'publish', data });
      };

      emit();
    }
  }, [status, data]);

  return (
    <>
      <div className={styles['chat']}>
        <ChatList messages={messages} />
        <ChatForm onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default ChatComponent;
