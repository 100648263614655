import { useReducer, useCallback } from 'react';
import { Credentials, RecoverPasswordData, Register } from '../types/auth-types';

export enum RequestStatus {
  pending = 'pending',
  completed = 'completed',
}

export enum ActionType {
  send = 'send',
  success = 'success',
  error = 'error',
}

export interface SuccessAction<T> {
  type: ActionType.success;
  responseData: T | null;
}

export interface ErrorAction {
  type: ActionType.error;
  errorMessage: null | string | string[];
  errorCode: null | number;
}

export interface SendAction {
  type: ActionType.send;
}

export type Action<T> = SuccessAction<T> | ErrorAction | SendAction;

export type RecoverPasswordFunction = (data: RecoverPasswordData) => Promise<string>;

export type LoginFunction = (data: Credentials) => Promise<string>;
export type RegisterFunction = (data: Register) => Promise<string>;

export type RequestFunctions = LoginFunction | RecoverPasswordFunction | RegisterFunction;

export interface HttpState<T> {
  data: T | null;
  error: null | string | string[];
  code: null | number;
  status: RequestStatus | null;
}

const httpReducer = <S>(state: HttpState<S>, action: Action<S>): HttpState<S> => {
  switch (action.type) {
    case ActionType.send:
      return {
        data: null,
        error: null,
        code: null,
        status: RequestStatus.pending,
      };
    case ActionType.success:
      return {
        data: action.responseData,
        error: null,
        code: null,
        status: RequestStatus.completed,
      };
    case ActionType.error:
      return {
        data: null,
        error: action.errorMessage,
        code: action.errorCode,
        status: RequestStatus.completed,
      };

    default:
      return state;
  }
};

interface RequestFunction<T, S> {
  (data: T): Promise<S>;
}

function useHttp<T, S>(requestFunction: RequestFunction<T, S>, startWithPending = false) {
  const [httpState, dispatch] = useReducer(httpReducer, {
    status: startWithPending ? RequestStatus.pending : null,
    data: null,
    code: null,
    error: null,
  });

  const sendRequest = useCallback(
    async function(requestData?: T) {
      dispatch({ type: ActionType.send });

      try {
        const responseData = await requestFunction(requestData as NonNullable<T>);
        dispatch({ type: ActionType.success, responseData } as Action<S>);
        return responseData;
      } catch (error) {

        dispatch({
          type: ActionType.error,
          errorMessage: error.message || 'Something went wrong!',
          errorCode: error.code,
        });
        return error as string;
      }
    },
    [requestFunction],
  );

  return {
    sendRequest,
    ...(httpState as HttpState<S>),
  };
}

export default useHttp;
