import React, { FunctionComponent } from 'react';

import { MODAL_NAVIGATION_DIRECTIONS } from 'src/utils/modal';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg';

import styles from './DialogExtendedNavigation.module.scss';

interface DialogExtendedNavigationProps {
  creditInfo?: string;
  handleSlideChange?: (direction: string) => void;
}

const DialogExtendedNavigation: FunctionComponent<DialogExtendedNavigationProps> = ({
  creditInfo,
  handleSlideChange
}) => (
  <div className={styles.Navigation}>
    <div className={styles.arrowBox} onClick={() => handleSlideChange && handleSlideChange(MODAL_NAVIGATION_DIRECTIONS.PREV)}>
      <ArrowLeft />
      <span>Previous</span>
    </div>
    <div className={styles.snippetWrapper}>
      <span>{creditInfo}</span>
    </div>
    <div className={styles.arrowBox} onClick={() => handleSlideChange && handleSlideChange(MODAL_NAVIGATION_DIRECTIONS.NEXT)}>
      <span>Next</span>
      <ArrowRight />
    </div>
  </div>
);

export default DialogExtendedNavigation;
