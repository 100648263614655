import React, { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ThreeDotsIcon } from 'src/assets/icons/threeDots.svg';
import { ReactComponent as SoundOnIcon } from 'src/assets/icons/soundOn.svg';
import { ReactComponent as SoundOffIcon } from 'src/assets/icons/soundOff.svg';
import AuthContext from 'src/store/auth-context';
import { AudioContext } from 'src/store/audio-context';
import classes from './Footer.module.scss';
import useHttp from 'src/hooks/use-http';
import { getNavigations, NavigationItem } from 'src/api/navigations-api';
import { Routes } from 'src/types/routes';
import { Roles } from 'src/types/user-types';

const Footer: FC = () => {
  const { isLoggedIn, handleLogout, user } = useContext(AuthContext);
  const { isMuted, toggleMute } = useContext(AudioContext);
  const [dotMenuOpen, setDotMenuOpen] = useState<boolean>(false);
  const onLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleLogout();
  };

  const { sendRequest: footerRequest, data: footerData, error: footerError } = useHttp(getNavigations);
  const [footerNavigation, setFooterNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    footerRequest();
  }, [footerRequest]);

  useEffect(() => {
    if (footerData && !footerError) {
      setFooterNavigation(footerData);
    }
  }, [footerData, footerError]);

  return (
    <footer className={classes.footer}>
      <p className={classes.legal}>
        created by
        <br />
        <span>lili</span> Studios
      </p>
      <div className={classes.menuContainer}>
        <div className={classes.soundIconWrapper} onClick={() => toggleMute()}>
          {isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
        </div>
        <button onClick={() => setDotMenuOpen(!dotMenuOpen)} className={classes.dotIcon}>
          <ThreeDotsIcon />
        </button>

        {dotMenuOpen && (
          <div className={classes.dotNav}>
            {footerNavigation
              .filter((item) => item.position === 'footer')
              .map((item, index) => (
                <Link key={index} className={classes.navItem} to={{ pathname: item.href }} target='_blank'>
                  {item.title}
                </Link>
              ))}

            {isLoggedIn && user.role === Roles.MODERATOR && (
              <Link to={Routes.SETTINGS} className={classes.navItem}>
                Settings
              </Link>
            )}

            {isLoggedIn && (
              <button onClick={onLogout} className={classes.navItem}>
                Log Out
              </button>
            )}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
