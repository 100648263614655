import { useContext, useEffect, useState } from 'react';
import { NavContext } from 'src/store/nav-context';
import classes from './NavAccordion.module.scss';
import { Link, useLocation } from 'react-router-dom';
import Bookmark from 'src/components/common/Bookmark/Bookmark';
import { toggleBookmark } from 'src/api/bookmarks-api';
import { Room } from 'src/types/room';
import { BookmarksContext } from 'src/store/bookmarks-context';

interface Props {
  items: Room[];
}

const NavAccordion: React.FC<Props> = ({ items }) => {
  const skyGallerLink = '/sky-gallery';
  const [currentLoacation, setCurrentLocation] = useState<string | null>(null);
  const { handleToggle } = useContext(NavContext);
  const windowLocation = useLocation();

  useEffect(() => {
    setCurrentLocation(windowLocation?.pathname);
  }, [windowLocation]);

  const handleLocationChange = (link: string) => {
    setCurrentLocation(link);
    handleToggle();
  };

  // const { bookmarks } = useContext(BookmarksContext);

  const generateItemList = (currentLoacation: string | null) => items.map((item, index) => {

    // const isBookmarked = bookmarks.some(bookmark => bookmark.room.id === item.id);
    const link = `/room/${item.id}`;
    const isActive = link === currentLoacation;

    return (
      <li key={index} className={`${classes['list-item']} ${isActive ? classes['active'] : ''}`}>
        <Link to={link} onClick={() => handleLocationChange(link)}>{item.name}</Link>
        {/* <Bookmark id={item.id} apiCall={() => toggleBookmark(item.id, isBookmarked)} bookmarked={isBookmarked} /> */}
      </li>
    );
  });

  return (
    <ol className={classes.list}>
      {generateItemList(currentLoacation)}
      {/* <li className={`${classes['list-item']} ${skyGallerLink === currentLoacation ? classes['active'] : ''}`}>
        <Link to="/sky-gallery" onClick={() => handleLocationChange('/sky-gallery')}>The Sky Gallery</Link>
      </li> */}
    </ol>
  );
};

export default NavAccordion;
