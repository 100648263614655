import axios from 'axios';
import { Tokens } from '../types/tokens';
import { getToken } from '../utils/storage';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(async (config) => {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    const token = getToken(Tokens.LOGIN);

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

export default api;
