import React, { FC } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';

import classes from './ForwardLink.module.scss';

export enum Variant {
    BUTTON = 'button',
    LINK = 'link',
}

interface Component {
    variant: Variant;
    cssClass?: string;
    description?: string;
    centered?: boolean;
}

interface ButtonLink extends React.ButtonHTMLAttributes<HTMLButtonElement>, Component {
    variant: Variant.BUTTON;
    onClick: () => void;
}

interface RouterLink extends RouterLinkProps, Component {
    variant: Variant.LINK;
}

const ForwardRouterLink: FC<RouterLink> = ({ children, cssClass = '', description, ...otherProps }) => (
    <Link className={classes['forward__link']} {...otherProps}>
        {children}
    </Link>
);

const ForwardButtonLink: FC<ButtonLink> = ({ children, cssClass = '', description, onClick, ...otherProps }) => (
    <button className={classes['forward__link']} type="button" onClick={onClick} {...otherProps}>
        {children}
    </button>
);

export type Props = ButtonLink | RouterLink;

const ForwardLink: FC<Props> = (props) => {
    const { cssClass = '', description, centered } = props;

    let link;

    if (props.variant === Variant.LINK) {
        link = <ForwardRouterLink {...props} />;
    } else if (props.variant === Variant.BUTTON) {
        link = <ForwardButtonLink {...props} />;
    }

    return (
        <div className={`${classes['forward']} ${cssClass} ${centered ? classes['forward--centered'] : ''}`}>
            {description ? <p className={classes['forward__description']}>{description}</p> : null}
            {link}
        </div>
    );
};

export default ForwardLink;
