import React, { useState, useEffect, useContext, FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { User, Roles } from '../types/user-types';
import { Tokens } from '../types/tokens';
import { getUserFromStorage } from '../utils/storage';

import { removeToken as removeLocalStorageToken, setToken as setLocalStorageToken } from '../utils/storage';
import Login from '../pages/Login';

const emptyFunction = () => {
  /**/
};

interface AuthContext {
  storageReady: boolean;
  isLoggedIn: boolean;
  isAuthModalOpen: boolean;
  handleCloseAuthModal: () => void;
  handleOpenAuthModal: () => void;
  handleLogout: () => void;
  handleLogin: (user: User, token?: string) => void;
  token: string;
  user: User;
}

const defaultState = {
  storageReady: false,
  isLoggedIn: false,
  isAuthModalOpen: false,
  handleCloseAuthModal: emptyFunction,
  handleOpenAuthModal: emptyFunction,
  handleLogout: emptyFunction,
  handleLogin: emptyFunction,
  token: '',
  user: {
    id: 0,
    username: '',
    email: '',
    role: '',
  },
};

const AuthContext = React.createContext<AuthContext>(defaultState);

export const AuthContextProvider: FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [storageReady, setStorageReady] = useState<boolean>(false);
  const [user, setUser] = useState<User>(defaultState.user);
  const [token, setToken] = useState<string>(defaultState.token);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const auth = getUserFromStorage();

    if (auth?.username && auth?.email && auth?.id && auth?.role && auth?.token) {
      setUser({
        id: auth.id,
        username: auth.username,
        email: auth.email,
        role: auth.role
      });
      setIsLoggedIn(true);
      setToken(auth.token);
    } else {
      handleLogout();
    }

    setStorageReady(true);
  }, []);

  const handleLogout = () => {
    removeLocalStorageToken(Tokens.LOGIN);
    setIsLoggedIn(false);
    setUser(defaultState.user);
    setToken('');
  };

  const handleLogin = (user: User, token?: string) => {
    if (token) {
      setLocalStorageToken(Tokens.LOGIN, token);
      setToken(token);
    }
    setUser(user);
    setIsLoggedIn(true);
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  const handleOpenAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  return (
    <AuthContext.Provider
      value={{
        storageReady,
        isLoggedIn,
        handleLogout,
        handleLogin,
        user,
        token,
        isAuthModalOpen,
        handleCloseAuthModal,
        handleOpenAuthModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute: FC<RouteProps> = ({ children, ...otherProps }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return <>{isLoggedIn ? <Route {...otherProps}>{children}</Route> : <Login />}</>;
};

export const StaffRoute: FC<RouteProps> = ({ children, ...otherProps }) => {
  const { isLoggedIn, user } = useContext(AuthContext);

  return <>{isLoggedIn && user.role === Roles.MODERATOR ? <Route {...otherProps}>{children}</Route> : <Redirect to={`/`} />}</>;
};

export default AuthContext;
