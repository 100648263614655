import { FieldInputProps } from 'formik';
import { FC } from 'react';
import Input from '../Input/Input';

import classes from './InputPassword.module.scss';

interface Props extends FieldInputProps<string> {
    showPassword: boolean;
    onShowPassword: () => void;
    labelFor?: string;
}

const InputPassword: FC<Props> = (props) => {
    const { showPassword, onShowPassword } = props;

    const parseProps = ({
        showPassword,
        onShowPassword,
        labelFor,
        ...rest
    }: Props): Omit<Props, 'showPassword' | 'onShowPassword' | 'labelFor'> => {
        return rest;
    };

    return (
        <div className={classes['input-password__wrapper']}>
            <Input
                // placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                {...parseProps(props)}
                id={props.labelFor}
                className={classes['input-password__input']}
            />
            <div className={classes['input-password__button-wrapper']}>
                <button className={classes['input-password__button']} type="button" onClick={onShowPassword}>
                    {showPassword ? 'Hide' : 'Show'}
                </button>
            </div>
        </div>
    );
};

export default InputPassword;
