import { FC, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import classes from './Modal.module.scss';

interface Props {
    onClose: () => void;
    cssClass?: string;
    closeButton?: boolean;
}

const portalElement = document.getElementById('modal');

const Modal: FC<Props> = ({ children, onClose, cssClass = '', closeButton }) => {
    if (!portalElement) return null;

    const backdropRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return function cleanup() {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleClose = (event: React.MouseEvent) => {
        if (backdropRef.current === event.target) {
            onClose();
        }
    };

    const modalOverlay = (
        <div ref={backdropRef} className={classes.backdrop} onClick={handleClose}>
            <div className={`${classes.modal} ${cssClass}`}>
                {closeButton && (
                    <div className={classes['modal__close']} onClick={onClose}>
                        X
                    </div>
                )}
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    );

    return <>{ReactDOM.createPortal(modalOverlay, portalElement)}</>;
};

export default Modal;
