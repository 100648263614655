import { FC, useContext, useEffect } from 'react';
import AuthContext from 'src/store/auth-context';
import AuthModal from '../auth/AuthModal/AuthModal';
import Navbar from '../navigation/Navbar/Navbar';

import Footer from './Footer/Footer';
import LogoHeader from './Header/LogoHeader';
import styles from './MainLayout.module.scss';

const MainLayout: FC = ({ children }) => {
  const { isAuthModalOpen } = useContext(AuthContext);

  return (
    <div className={styles.Body}>
      <Navbar />
      <LogoHeader />
      <div className={styles.Content}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
