import { DataProps } from './DialogComponent';

export const filterContent = (data: DataProps[], assetId: number) => data.find((asset: DataProps) => asset.id === assetId);
export const findContentIndex = (data: DataProps[], assetId: number) => data.findIndex((asset: DataProps) => asset.id === assetId);
export const validateIndex = (data: DataProps[], nextIndex: number) => {
  const assetsLength = data.length || 0;

  if (nextIndex === -1) return assetsLength - 1;
  if (nextIndex === assetsLength) return 0;

  return nextIndex;
}
