import { useEffect, FC, useContext } from 'react';
import { recoverPassword, validateRecoverCode } from '../../../api/auth-api';
import useHttp, { RequestStatus } from '../../../hooks/use-http';
import useCountdown from '../../../hooks/use-countdown';
import RecoverPasswordForm from './RecoverPasswordForm';
import { Passwords } from '../../../types/auth-types';
import Spinner from '../../common/Spinner/Spinner';
import InfoContainer from 'src/components/common/InfoContainer/InfoContainer';
import { useHistory } from 'react-router';
import AuthContext from '../../../store/auth-context';
import { Routes } from 'src/types/routes';

interface Props {
    code: string;
    userID: string;
}

const TIME_TO_REDIRECT = 3000;

const RecoveryPasswordFormContainer: FC<Props> = ({ code, userID }) => {
    const { push } = useHistory();
    const { handleLogin } = useContext(AuthContext);
    const { countdown, start: startCountdown } = useCountdown({
        timer: TIME_TO_REDIRECT,
        onExpire: () => push(Routes.MAIN),
    });

    const {
        sendRequest: changePasswordRequest,
        status: changePasswordStatus,
        error: changePasswordResponseError,
        data: changePasswordResponseData,
    } = useHttp(recoverPassword);

    const {
        sendRequest: validateCodeRequest,
        status: validateCodeStatus,
        data: validateCodeResponseData,
        error: validateCodeResponseError,
    } = useHttp(validateRecoverCode, true);

    useEffect(() => {
        validateCodeRequest({ userID, code });
    }, []);

    useEffect(() => {
        if (
            changePasswordResponseData &&
            changePasswordStatus === RequestStatus.completed &&
            !changePasswordResponseError
        ) {
            const userData = {
                id: changePasswordResponseData.user?.id,
                username: changePasswordResponseData.user?.username,
                email: changePasswordResponseData.user?.email,
                role: changePasswordResponseData.user?.role.name,
            };
            const token = changePasswordResponseData.jwt;

            handleLogin(userData, token);

            startCountdown();
        }
    }, [changePasswordResponseData, changePasswordStatus, changePasswordResponseError]);

    const onSubmitHandle = (values: Passwords) => {
        return changePasswordRequest({ passwords: values, code, userID });
    };

    const codeValidationError = validateCodeStatus === RequestStatus.completed && validateCodeResponseError;
    const codeSuccessfullyValidated =
        validateCodeStatus === RequestStatus.completed && validateCodeResponseData && !validateCodeResponseError;

    const passwordSuccessfullyChanged =
        changePasswordStatus === RequestStatus.completed && !changePasswordResponseError;
    const changePasswordError = changePasswordStatus === RequestStatus.completed && changePasswordResponseError;

    let content: string | JSX.Element = <Spinner height={80} thickness={10} />;

    if (codeValidationError) {
        content = (
            <InfoContainer title="Invalid code">
                Your code is invalid. It could be expired or already used.
            </InfoContainer>
        );
    }

    if (codeSuccessfullyValidated) {
        content = <RecoverPasswordForm error={changePasswordResponseError} onSubmit={onSubmitHandle} />;
    }

    if (codeSuccessfullyValidated && passwordSuccessfullyChanged) {
        content = (
            <InfoContainer title="Your password has been successfully changed">
                You will be logged in and redirected in {countdown / 1000} seconds
            </InfoContainer>
        );
    }

    if (codeValidationError && changePasswordError) {
        content = <InfoContainer title="Something went wrong">Try again later.</InfoContainer>;
    }

    return <>{content}</>;
};

export default RecoveryPasswordFormContainer;
