import React, { FunctionComponent } from 'react';

import DialogDefaultNavigation from '../../../DialogDefaultNavigation/DialogDefaultNavigation';
import { ContentProps } from '../../DialogComponent';

import styles from './ImageContent.module.scss';
import ReactMarkdown from 'react-markdown';

const ImageContent: FunctionComponent<ContentProps> = ({
  asset,
  caption = '',
  creditInfo = '',
  onClose,
  handleSlideChange
}) => (
  <div className={styles.Container}>
    <div className={styles.ImageWrapper}>
      <img src={asset?.url} alt={asset?.name} />
    </div>
    <div className={styles.TextContainer}>
      <div className={styles.TextWrapper}>
        <div className={styles.ContentWrapper}>
          <ReactMarkdown
            children={caption}
            linkTarget='_blank'
          />
        </div>
        <div className={styles.mask}/>
      </div>
      {creditInfo && <div className={styles.CreditInfo}>{creditInfo}</div>}
      <DialogDefaultNavigation handleSlideChange={handleSlideChange} onClose={onClose} />
    </div>
  </div>
);

export default ImageContent;
