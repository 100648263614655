import { FunctionComponent } from 'react';

import { ContentProps } from '../../DialogComponent';

import styles from './AudioContent.module.scss';

const AudioContent: FunctionComponent<ContentProps> = () => (
  <div className={styles.Container}>
    <p>AUDIO CONTENT</p>
  </div>
);

export default AudioContent;
