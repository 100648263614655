import React, { FC } from 'react';
import classes from './Checkbox.module.scss';

const CheckIcon: FC = () => (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.09518 0.910812C9.42062 1.23625 9.42062 1.76389 9.09518 2.08932L3.92259 7.50599C3.59715 7.83143 3.06951 7.83143 2.74408 7.50599L0.244078 5.00599C-0.0813593 4.68056 -0.0813593 4.15292 0.244078 3.82748C0.569515 3.50204 1.09715 3.50204 1.42259 3.82748L3.33333 5.73822L7.91667 0.910812C8.2421 0.585375 8.76974 0.585375 9.09518 0.910812Z"
            fill="white"
        />
    </svg>
);

interface Props {
    name: string;
    checked: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<Props> = ({ onChange, checked, name }) => {
    return (
        <label className={classes.Checkbox}>
            <input type="checkbox" name={name} onChange={onChange} checked={checked} />
            {checked && <CheckIcon />}
        </label>
    );
};

export default Checkbox;
