import React, { FC, useContext } from 'react';
import ChatComponent from '../components/chat/Chat';
import { ChatContextProvider } from '../store/chat-context';
import { ConfigContext } from '../store/config-context';
import PageUnavailable from '../components/common/PageUnavailable';

const Chat: FC = () => {

  const { options } = useContext(ConfigContext);

  if(!options?.chat) {
    return(
      <PageUnavailable />
    );
  }

  return (
    <ChatContextProvider>
      <ChatComponent />
    </ChatContextProvider>
  );
};

export default Chat;
