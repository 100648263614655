import { FC } from 'react';
import SectionContainer from '../SectionContainer/SectionContainer';
import Title from '../Title/Title';
import classes from './Form.module.scss';

interface Props {
    title?: string;
    cssClass?: string;
    onSubmit: (event?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const Form: FC<Props> = ({ title, children, cssClass = '', onSubmit }) => {
    const formClass = `${classes['form']} ${cssClass}`;

    return (
        <SectionContainer>
            <Title title={title} />
            <form className={formClass} onSubmit={onSubmit}>
                {children}
            </form>
        </SectionContainer>
    );
};

export default Form;
