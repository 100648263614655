import React, { FC, useContext, useEffect, useRef, useState, useCallback } from 'react';
import classes from './Entrance.module.scss';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import { RoomContainer } from '../room/Room.container';
import AuthModal from '../auth/AuthModal/AuthModal';
import { isMobile } from "react-device-detect";
import AuthContext from 'src/store/auth-context';
import { AudioContext } from 'src/store/audio-context';
import AnimatedLogo from '../common/AnimatedLogo/AnimatedLogo';
import { Player, PlayerOptions, loadImaSdk } from '@glomex/vast-ima-player';
import useHttp from 'src/hooks/use-http';
import { getConfig } from 'src/api/config-api';
import { calculateVideoProgress } from '../room/components/DialogComponent/DialogComponentTypes/VideoContent/VideoContent.utils';

/*IMPORANT there is an issue with the video on iOS devices, it appears when the user uses the #register in url. The reason is that safari doesn't load the second video without any user interaction it also has to do with the video having sound. The second video doesn't have sound so the video tag muted solved the issue, but for the future if the video has sound the user interaction should be added for the second video to load. Loading the video when the user clicks the login/register button could be a good solution  */
enum ENTRANCE_STATE {
  START = 'start',
  LOG_IN = 'logIn',
  SHOW_MID_VIDEO = 'showMidVid',
  SHOW_ROOM = 'showRoom'
}
enum BACKGROUND_STATE {
  FIRST = 'first',
  SECOND = 'second',
  FINAL = 'final'
}
enum COMPONENT_STATE {
  ENTER,
  VIDEO_1_STARTED,
  VIDEO_1_ENDED,
  MID_VIDEO_STARTED,
  MID_VIDEO_ENDED,
  USER_ACTION_FINISHED,
  VIDEO_2_STARTED,
  VIDEO_2_ENDED,
}
enum VIDEO {
  FIRST = 'first',
  MIDDLE = 'middle',
  SECOND = 'second',
  NONE = 'none'
}
enum VIDEO_SOURCE {
  MOBILE_FIRST = 'https://d1wt1h6uciv02m.cloudfront.net/Entrance_3_4_Intro_Video_3077cc1c49.mp4',
  MOBILE_SECOND = 'https://d1wt1h6uciv02m.cloudfront.net/Entrance_3_4_Outro_Video_c882a27f03.mp4',
  DESKTOP_FIRST = 'https://d1wt1h6uciv02m.cloudfront.net/Entrance_intro_Video_ec6dc42bb1.mp4',
  DESKTOP_SECOND = 'https://d1wt1h6uciv02m.cloudfront.net/Entrance_outro_Video_4f85b23fb1.mp4',
  MIDDLE_VIDEO = 'https://lili-strapi-uploads.s3.amazonaws.com/HOD_JC_INTRO_EDIT_V11_21efef7611.mp4',
}

const adTag = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21831397253/LOFFICIEL100_PREROLL&description_url=http%3A%2F%2Flofficiel100.com&tfcd=0&npa=0&sz=640x480&cust_params=market%3Dusa&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';

const EntranceComponent: FC = () => {
  const { sendRequest: configRequest, data: configData, error: configError } = useHttp(getConfig);
  const { isMuted } = useContext(AudioContext);
  const { isLoggedIn } = useContext(AuthContext);
  const video1Ref = React.useRef<HTMLVideoElement | null>(null);
  const video2Ref = React.useRef<HTMLVideoElement | null>(null);
  const video3Ref = React.useRef<any>(null);
  const addContainerRef = React.useRef<any>(null);
  const [pageState, setPageState] = useState<ENTRANCE_STATE>(ENTRANCE_STATE.START);
  const [backgroundState, setBackgroundState] = useState<BACKGROUND_STATE>(BACKGROUND_STATE.FIRST);
  const [showRoom, setShowRoom] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<VIDEO>(VIDEO.NONE);
  const [hideButton, setHideButton] = useState<boolean>(false);
  const isFromRegister = window.location.hash === '#register';
  const [isAdFinished, setIsAddFinished] = useState<boolean>(false);
  const [imaPlayer, setImaPlayer] = useState<any>(null);
  const [ad, setAd] = useState<unknown>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [showSkipVideoButton, setShowSkipVideoButton] = useState<boolean>(false);
  const [showRegistration, setShowRegistration] = useState<boolean>(false);
  const [isMainVideoVisible, setIsMainVideoVisible] = useState<boolean>(false);

  useEffect(
    () => {
      configRequest()
      if (isFromRegister) {
        isLoggedIn ? goToLobby() : skipIntroVideo();
      }
    }, []
  )
  useEffect(
    () => {
      if (configData && !configError) {
        setShowRegistration(configData.showRegistration)
      }

    }, [configData, configError]
  )
  // useEffect(
  //   () => {
  //     if (isMobile && isAdFinished && !isPlaying) {
  //       setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED)
  //     }

  //   }, [isAdFinished, isPlaying, isMobile]
  // )

  useEffect(() => {
    setIsMainVideoVisible(pageState === ENTRANCE_STATE.SHOW_MID_VIDEO);
  }, [pageState]);

  useEffect(() => {
    if (isMainVideoVisible && !ad) {
      loadImaSdk().then((ima: any) => {
        const adsRenderingSettings = new ima.AdsRenderingSettings();
        const adContainer = addContainerRef;
        const playerOptions = new PlayerOptions();
        const player = new Player(
          ima,
          video3Ref?.current,
          adContainer.current,
          adsRenderingSettings,
          playerOptions
        );
        ima.settings.setVpaidMode(
          ima.ImaSdkSettings.VpaidMode.INSECURE
        );
        ima.settings.setNumRedirects(3);
        player.addEventListener('AdComplete', () => {
          setIsAddFinished(true);
        });
        player.addEventListener('AdPaused', () => {
          player.play();
        });
        player.addEventListener('AdError', () => {
          setIsAddFinished(true);
        });
        const playAdsRequest = new ima.AdsRequest();
        playAdsRequest.adTagUrl = adTag;
        setAd(playAdsRequest);

        setImaPlayer(player);
        setTimeout(() => {
          setIsLoaded(true);
        }, 1000);
      });
    }
  }, [isMainVideoVisible]);

  const skipIntroVideo = () => {
    video2Ref.current?.load();
    video3Ref.current?.load();
    setHideButton(true);

    setShowVideo(showRegistration ? VIDEO.NONE : VIDEO.MIDDLE);
    setBackgroundState(BACKGROUND_STATE.SECOND);
    setPageState(showRegistration ? ENTRANCE_STATE.LOG_IN : ENTRANCE_STATE.SHOW_MID_VIDEO);
  }
  const goToLobby = () => {
    setHideButton(true);
    setShowRoom(true);
    setShowVideo(VIDEO.NONE);
    setBackgroundState(BACKGROUND_STATE.FINAL);
    setPageState(ENTRANCE_STATE.SHOW_ROOM);
  }
  const handleSkip = useCallback(
    () => {
      const { current: video } = video3Ref;
      video.pause();
      video.currentTime = 0;
      setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED)
    }, [video3Ref]
  )

  useEffect(() => {
    if (video3Ref && video3Ref.current && isLoaded) {
      video3Ref.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    if (video3Ref && isLoaded) {
      const { current: video } = video3Ref;
      video.volume = 0.3;
      if (video3Ref && imaPlayer && !isAdFinished) {
        imaPlayer.playAds(ad);
      } else {
        video.play();
      }
    }
  }, [video3Ref, isLoaded, isAdFinished]);

  const togglePlay = () => setIsPlaying(prev => {
    if (video3Ref) {
      const { current: video } = video3Ref;
      prev ? video.pause() : video.play();
    }
    return false;
  });

  useEffect(() => {
    /*eslint-disable */
    if (isPlaying && isLoaded && isAdFinished) {
      if (video3Ref && video3Ref.current) {
        video3Ref.current.muted = isMuted;
      }
      let intervalId: NodeJS.Timeout;
      intervalId = setInterval(() => {
        if (video3Ref && isPlaying) {
          const currentTime = calculateVideoProgress(video3Ref.current) as any || 0;
          setDuration(parseInt(currentTime));
        }
        setCounter(prev => prev + 1);
      }, 100);
      if (duration >= 99) {
        setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED);
        setComponentState(COMPONENT_STATE.MID_VIDEO_ENDED);
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }
    /*eslint-enable */
  }, [counter, isPlaying, isLoaded, isAdFinished]);

  const setComponentState = (state: COMPONENT_STATE) => {
    if (state === COMPONENT_STATE.ENTER) {
      setHideButton(true);
      video1Ref.current?.play();
      video2Ref.current?.load();
      video3Ref.current?.load();

    } else if (state === COMPONENT_STATE.VIDEO_1_STARTED) {
      setShowVideo(VIDEO.FIRST);
      setTimeout(() => setBackgroundState(BACKGROUND_STATE.SECOND), 1000);

    } else if (state === COMPONENT_STATE.VIDEO_1_ENDED) {
      if (showRegistration) {
        setShowVideo(VIDEO.NONE);
        if (!isLoggedIn) { setPageState(ENTRANCE_STATE.LOG_IN); }
        else { setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED); }
      } else {
        setPageState(ENTRANCE_STATE.SHOW_MID_VIDEO);
        setShowVideo(VIDEO.MIDDLE)
      }

    } else if (state === COMPONENT_STATE.MID_VIDEO_STARTED) {
      setIsPlaying(true)

      setTimeout(() => setShowSkipVideoButton(true), 30000)
    } else if (state === COMPONENT_STATE.MID_VIDEO_ENDED) {
      setIsPlaying(false);
      setCounter(0);
      setDuration(0);
      if (isMobile) {
        if (isAdFinished && !isPlaying) {
          setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED)
        }
      } else {
        setComponentState(COMPONENT_STATE.USER_ACTION_FINISHED)
      }

    } else if (state === COMPONENT_STATE.USER_ACTION_FINISHED) {
      video2Ref.current?.play()

    } else if (state === COMPONENT_STATE.VIDEO_2_STARTED) {
      setShowVideo(VIDEO.SECOND);
      setTimeout(() => setBackgroundState(BACKGROUND_STATE.FINAL), 1000);
      setPageState(ENTRANCE_STATE.SHOW_ROOM);

    } else if (state === COMPONENT_STATE.VIDEO_2_ENDED) {
      setShowRoom(true);
      setShowVideo(VIDEO.NONE);

    }
    return;
  }

  return (
    <>
      <div className={`${classes.videoContainer} ${classes.setBackgroundImage} ${backgroundState === BACKGROUND_STATE.SECOND ? classes.second : ''} ${backgroundState === BACKGROUND_STATE.FINAL ? classes.final : ''} `}>
        <video
          playsInline
          ref={video1Ref}
          onPlay={() => setComponentState(COMPONENT_STATE.VIDEO_1_STARTED)}
          onEnded={() => setComponentState(COMPONENT_STATE.VIDEO_1_ENDED)}
          className={`${showVideo === VIDEO.FIRST ? classes.showVideo : ''}`}>
          <source src={isMobile ? VIDEO_SOURCE.MOBILE_FIRST : VIDEO_SOURCE.DESKTOP_FIRST} type='video/mp4' />
        </video>
        <video
          playsInline
          ref={video2Ref}
          muted
          onPlay={() => setComponentState(COMPONENT_STATE.VIDEO_2_STARTED)}
          onEnded={() => setComponentState(COMPONENT_STATE.VIDEO_2_ENDED)}
          className={`${showVideo === VIDEO.SECOND ? classes.showVideo : ''}`}>
          <source src={isMobile ? VIDEO_SOURCE.MOBILE_SECOND : VIDEO_SOURCE.DESKTOP_SECOND} type='video/mp4' />
        </video>

        {pageState === ENTRANCE_STATE.SHOW_ROOM && <div className={`${classes.roomContainer} ${showRoom ? classes.showRoom : ''}`}>
          <RoomContainer roomNumber='20' />
        </div>}

      </div>
      {pageState === ENTRANCE_STATE.SHOW_MID_VIDEO &&
      <div className={`${classes.midVidContainer} ${showVideo === VIDEO.MIDDLE ? classes.showMidVid : ''}`}>
        <div className={classes.vidPlayerWrapper}>
          <div className={classes.videoWrapper}>
            <video
              playsInline
              ref={video3Ref}
              src={VIDEO_SOURCE.MIDDLE_VIDEO}
              autoPlay
              preload='auto'
              onPlay={() => setComponentState(COMPONENT_STATE.MID_VIDEO_STARTED)}
              onEnded={() => setComponentState(COMPONENT_STATE.MID_VIDEO_ENDED)}
              onLoadedData={() => setIsLoaded(true)}
              className={`${showVideo === VIDEO.MIDDLE ? classes.showVideo : ''}`}
            >
            </video>
            <div className={classes.AddContainer} ref={addContainerRef}></div>
          </div>
          <div className={`${classes.ControlsWrapper} ${ad && !isAdFinished && classes.ControlsWrapperDisabled}`}>
            <div
              className={classes.IconBox}
              onClick={togglePlay}
            >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </div>
            <div className={classes.Timeline}>
              <span style={{ width: `${duration}%` }}></span>
            </div>
            {showSkipVideoButton && <span className={classes.CloseButton} onClick={handleSkip}>Skip</span>}
          </div>
        </div>

      </div>}
      {pageState === ENTRANCE_STATE.LOG_IN && <div className={classes.formContainer}>
        <AuthModal />
      </div>}
      <AnimatedLogo handleClick={() => setComponentState(COMPONENT_STATE.ENTER)} handleSkip={skipIntroVideo} cssClass={hideButton ? classes.hideButton : ''} />
    </>

  );
};

export default EntranceComponent;
