import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Options } from 'src/types/config-types';
import styles from './styles.module.scss';

interface Props {
  onSubmit: (values: Options) => Promise<Options>;
  options: Options;
}

const SettingsForm: React.FC<Props> = ({ options, onSubmit }) => {
  
  return (

    <Formik initialValues={options} onSubmit={onSubmit}>
      <Form className={styles['settings-form']}>
        <ul className={styles['settings-list']}>
          <li>
            <label className={styles['settings-list__label']}>
              <span>
                Chat
              </span>
              <Field name={`chat`} type={`checkbox`} />
            </label>
          </li>
        </ul>

        <button type={`submit`} className={styles['settings-form__button']}>Save</button>

      </Form>
    </Formik>

  );
};

export default SettingsForm;
