import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './Bookmarks.module.scss';
import { Bookmark } from '../../types/bookmark';

interface Props {
  data: Bookmark
}

const BookmarkPreview: FC<Props> = ({ data }: Props) => {
  return (
    <div className={styles['bookmark']}>
      <Link to={`/room/${data.room.id}`}>{data.room.name}</Link>
    </div>
  );
};

export default BookmarkPreview;
