import { AxiosResponse, AxiosError } from 'axios';
import api from './settings';
import { Options } from 'src/types/config-types';

export function getConfig() {
  return api.get('/config/')
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError) => {
      throw new Error(error?.response?.data?.message);
    });
}

export function setConfig(values: Options) {
  return api.put('/config/', values)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      throw new Error(error?.response?.data?.message);
    });
}
