import { useState, FC } from 'react';
import { useFormik } from 'formik';
import Button from '../../common/Button/Button';
import Spinner from '../../common/Spinner/Spinner';
import Form from '../../common/Form/Form';
import Field from '../../common/Field/Field';
import { passwordValidators, secondPasswordValidators } from 'src/utils/validators';
import * as Yup from 'yup';
import classes from './RecoverPasswordForm.module.scss';
import InputPassword from '../../common/InputPassword/InputPassword';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';

interface Passwords {
    password: string;
    secondPassword: string;
}

interface Props {
    error: string | string[] | null;
    onSubmit: (values: Passwords) => Promise<unknown>;
}

const RecoverPasswordForm: FC<Props> = ({ error, onSubmit }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const RecoverPasswordSchema = Yup.object().shape({
        password: passwordValidators,
        secondPassword: secondPasswordValidators('password'),
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = useFormik({
        initialValues: {
            password: '',
            secondPassword: '',
        },
        onSubmit: onSubmit,
        validationSchema: RecoverPasswordSchema,
    });

    const changePasswordShow = () => setShowPassword((state) => !state);

    return (
        <Form onSubmit={handleSubmit} title="New password">
            <Field
                label="Password"
                input={
                    <InputPassword
                        {...getFieldProps('password')}
                        showPassword={showPassword}
                        onShowPassword={changePasswordShow}
                    />
                }
                error={errors.password}
                touched={touched.password}
            ></Field>

            <Field
                label="Confirm password"
                input={
                    <InputPassword
                        {...getFieldProps('secondPassword')}
                        showPassword={showPassword}
                        onShowPassword={changePasswordShow}
                    />
                }
                error={errors.secondPassword}
                touched={touched.secondPassword}
            ></Field>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            {isSubmitting ? (
                <Spinner height={40} thickness={10} />
            ) : (
                <Button cssClass={classes['recovery-form__submit-button']} type="submit">
                    Reset password
                </Button>
            )}
        </Form>
    );
};

export default RecoverPasswordForm;
