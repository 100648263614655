import React, { useEffect, useContext, useRef, useState } from 'react';
import { AudioContext } from 'src/store/audio-context';
import { NavContext } from 'src/store/nav-context';
import { setupRoom } from './setupRoom';
import styles from './Room.module.scss';
import { useHistory } from 'react-router-dom';
import { DialogComponent } from './components';
import Modal from '../common/Modal/Modal';
import { TextureBoxesTypes } from 'src/types/room';

interface RoomProps {
  roomConfiguration?: RoomConfiguration;
  debug?: boolean;
}

const Room = ({ roomConfiguration, debug = false }: RoomProps) => {
  const { handleRoomName } = useContext(NavContext);
  const { handleSourceChange, setAudioAvailability } = useContext(AudioContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [objectInfo, setObjectInfo] = useState<RoomObjectInfo | null>(null);
  const [clickableBoxes, setClickableBoxes] = useState<TextureBoxDefinition[] | undefined>([]);

  const setRoomObjectInfo = (data: RoomObjectInfo) => {
    if (data) {
      setIsOpen(true);
      setObjectInfo(data);
    }
  };

  useEffect(() => {
    if (roomConfiguration) {
      const { name, audio, textureBoxes } = roomConfiguration;
      const filteredBoxes = textureBoxes?.filter((item) => item.isClickable && item.type !== TextureBoxesTypes.LINK);
      handleRoomName(name)
      audio && handleSourceChange(audio.url);
      setAudioAvailability(!!audio);
      setClickableBoxes(filteredBoxes);
      closeModal();
    }
  }, [roomConfiguration]);

  // useEffect(() => {
  //   if (!window.location.origin.startsWith('http://localhost') && !localStorage.getItem('hyper-dev')) {
  //     if (prompt('Enter access password') !== process.env.REACT_APP_LOFFICIEL_PASSWORD) {
  //       document.body.remove();
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (!container || typeof roomConfiguration !== 'object') {
      return;
    }

    const { dispose } =
      setupRoom({
        container,
        setRoomObjectInfo,
        cssRendererClassName: styles['room__css-renderer'],
        roomConfiguration,
        history,
        debug,
      }) ?? {};

    return () => {
      if (dispose) {
        dispose();
      }
    };
  }, [roomConfiguration, history]);

  const closeModal = () => {
    setObjectInfo(null);
    setIsOpen(false);
  };

  return (
    <div className={styles['room']}>
      <div ref={containerRef} className={styles['room__container']}/>
      {isOpen && objectInfo && <Modal onClose={closeModal} cssClass={styles.Modal}>
        <DialogComponent {...objectInfo} data={clickableBoxes} onClose={closeModal} />
      </Modal>}
    </div>
  );
};

export { Room };
