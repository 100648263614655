import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import { NavContextProvider } from './store/nav-context';
import { BookmarksContextProvider } from './store/bookmarks-context';
import { AudioContextProvider } from './store/audio-context';
import { ConfigContextProvider } from './store/config-context';
import { ToastContainer as NotificationContainer } from 'react-toastify';
import setupIoT from './utils/iot';
import 'react-toastify/dist/ReactToastify.min.css';

if (process.env.REACT_APP_GTM_CONTAINER_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

if (window.location.origin.startsWith('https://dev-lofficiel-app.lili.network') && !localStorage.getItem('hyper-dev')) {
  if (prompt('Enter access password') !== process.env.REACT_APP_LOFFICIEL_PASSWORD) {
    document.body.remove();
  }
}

(async () => await setupIoT(true))();

ReactDOM.render(
  <React.StrictMode>
    <ConfigContextProvider>
      <AudioContextProvider>
        <AuthContextProvider>
          <NavContextProvider>
            <BookmarksContextProvider>
              <Router>
                <App />
                <NotificationContainer limit={3} hideProgressBar={true} />
              </Router>
            </BookmarksContextProvider>
          </NavContextProvider>
        </AuthContextProvider>
      </AudioContextProvider>
    </ConfigContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
