import api from './settings';

export interface NavigationItem {
  id: number;
  title: string;
  href: string;
  position: string;
  published_at: string;
}

export function getNavigations() {
  return api
    .get('/navigation', {
      params: {
        '_sort': 'title',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
