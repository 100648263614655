import { FC, useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import styles from '../Chat.module.scss';
import AuthContext from 'src/store/auth-context';
import { Message, NewMessage } from 'src/types/chat-types';

interface Props {
  onSubmit: (message: NewMessage) => Promise<Message>;
}

const ChatForm: FC<Props> = ({ onSubmit }) => {

  const { isLoggedIn, user } = useContext(AuthContext);

  if (!isLoggedIn) {
    return (
      <a href={`/login`} className={styles['chat-form__login']}>Log in to chat</a>
    );
  }

  return (
    <Formik
      initialValues={{
        message: '',
        highlighted: false,
      }}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm({});
      }}
    >
      <Form className={styles['chat-form']}>
        {user.role === 'Moderator' && (
          <label className={styles['chat-form__highlight']}>
            <Field
              name='highlighted'
              type={`checkbox`}
              title={`Highlight message`}
            />
            <svg className={styles['chat-form__highlight-icon']}
                 enableBackground='new 0 0 24 24'
                 height='512'
                 width='512'
                 viewBox='0 0 24 24'
                 xmlns='http://www.w3.org/2000/svg'>
              <path
                d='m23.363 8.584-7.378-1.127-3.307-7.044c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127c-.606.093-.848.83-.423 1.265l5.36 5.494-1.267 7.767c-.101.617.558 1.08 1.103.777l6.59-3.642 6.59 3.643c.54.3 1.205-.154 1.103-.777l-1.267-7.767 5.36-5.494c.425-.436.182-1.173-.423-1.266z'
                fill='#fff' />
            </svg>
          </label>
        )}
        <Field
          required
          maxLength={200}
          autoComplete={`off`}
          name='message'
          placeholder={`Type your message...`}
          className={styles['chat-form__input']}
        />
      </Form>
    </Formik>
  );

};

export default ChatForm;
