import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  data?: RoomSEO | null;
}

const RoomMeta: React.FC<Props> = ({ data }) => {

  if (!data) {
    return <></>;
  }

  return (
    <Helmet>
      <meta property='og:title' content={data.title} />
      <meta property='og:description' content={data.description} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={data.title} />
      <meta name='twitter:description' content={data.description} />

      <meta property='og:image' content={data.image?.url} />
      <meta property='og:image:secure_url' content={data.image?.url} />
      <meta property='og:image:alt' content={data.image?.name} />
      <meta property='og:image:type' content={data.image?.mime} />
      <meta name='twitter:image' content={data.image?.url} />

      {data.link && (
        <link rel={data.link.rel} href={data.link.href} />
      )}

    </Helmet>
  );

};

export default RoomMeta;
