import React from 'react';
import SettingsPage from '../components/settings/SettingsPage';

const Settings: React.FC = () => {
  return (
    <SettingsPage />
  );
};

export default Settings;
