export interface User {
  id: number;
  username: string;
  email: string;
  role: string;
}

export interface Email {
  email: string;
}

export enum Roles {
  PUBLIC = 'Public',
  AUTHENTICATED = 'Authenticated',
  MODERATOR = 'Moderator'
}
