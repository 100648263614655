import e from 'express';
import {
    BackSide,
    BoxBufferGeometry,
    Mesh,
    MeshBasicMaterial,
    PerspectiveCamera,
    Raycaster,
    Scene,
    TextureLoader,
    Vector2,
    WebGLRenderer,
} from 'three';
import { disposeObjectTree } from './vendor/lume/three';
import { CSS3DRenderer } from './vendor/three/CSS3DRenderer';
import { OrbitControls } from './vendor/three/OrbitControls';

interface SetupPanoramaParams {
    container: HTMLDivElement;
    cssRendererClassName: string;
    faces: RoomFace[];
    panoramaBoxSize?: number;
    debug?: boolean;
}

const setupPanorama = ({
    container,
    cssRendererClassName,
    faces,
    panoramaBoxSize = 10000,
    debug = false,
}: SetupPanoramaParams) => {
    const renderer = new WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    const scene = new Scene();
    const camera = new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, panoramaBoxSize);
    camera.position.z = 1;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.rotateSpeed = -0.25;

    const textureLoader = new TextureLoader();
    const panoramaMaterials = faces.map((face) => {

        const texture = textureLoader.load(face.face.url);
        texture.center.x = 0.5;
        texture.center.y = 0.5;
        texture.rotation = Math.PI;
        texture.flipY = false;
        return new MeshBasicMaterial({ map: texture, side: BackSide, depthTest: false });
    });

    const panorama = new Mesh(
        new BoxBufferGeometry(panoramaBoxSize, panoramaBoxSize, panoramaBoxSize),
        panoramaMaterials
    );
    panorama.renderOrder = -1;
    scene.add(panorama);

    // simpler way to do the panorama, but I'm not sure about dimensions
    // panorama would have to be replaced with scene in click event handler and returned object
    // texture order would need to be changed in config
    // scene.background = new CubeTextureLoader().load(textureUrls)

    const cssScene = new Scene();
    const cssRenderer = new CSS3DRenderer();
    cssRenderer.setSize(container?.clientWidth, container?.clientHeight);
    cssRenderer.domElement.classList.add(cssRendererClassName);
    container.appendChild(cssRenderer.domElement);

    const handleResize = () => {
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(container.clientWidth, container.clientHeight);
        cssRenderer.setSize(container.clientWidth, container.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    const raycaster = new Raycaster();
    const mouse = new Vector2();

    const handleClick = (event: MouseEvent) => {
        const { left, top } = renderer.domElement.getBoundingClientRect();
        const { clientWidth, clientHeight } = renderer.domElement;
        mouse.x = ((event.clientX - left) / clientWidth) * 2 - 1;
        mouse.y = (-(event.clientY - top) / clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(panorama.children, true);

        if (debug) {
            const [panoramaIntersect] = raycaster.intersectObject(panorama);
            const { point: panoramaPosition } = panoramaIntersect ?? {};
            console.log(panoramaPosition);
        }

        intersects.forEach((intersect) => {
            intersect.object.dispatchEvent({ type: 'click', intersects, mouseEvent: event });
        });
    };
    const handleMouseDown = (event: MouseEvent) => {
        const { left, top } = renderer.domElement.getBoundingClientRect();
        const { clientWidth, clientHeight } = renderer.domElement;
        mouse.x = ((event.clientX - left) / clientWidth) * 2 - 1;
        mouse.y = (-(event.clientY - top) / clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(panorama.children, true);

        if (debug) {
            const [panoramaIntersect] = raycaster.intersectObject(panorama);
            const { point: panoramaPosition } = panoramaIntersect ?? {};
            console.log(panoramaPosition);
        }

        intersects.forEach((intersect) => {
            intersect.object.dispatchEvent({ type: 'mousedown', intersects, mouseEvent: event });
        });
    };
    const handleMouseUp = (event: MouseEvent) => {
        const { left, top } = renderer.domElement.getBoundingClientRect();
        const { clientWidth, clientHeight } = renderer.domElement;
        mouse.x = ((event.clientX - left) / clientWidth) * 2 - 1;
        mouse.y = (-(event.clientY - top) / clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(panorama.children, true);

        if (debug) {
            const [panoramaIntersect] = raycaster.intersectObject(panorama);
            const { point: panoramaPosition } = panoramaIntersect ?? {};
            console.log(panoramaPosition);
        }

        intersects.forEach((intersect) => {
            intersect.object.dispatchEvent({ type: 'mouseup', intersects, mouseEvent: event });
        });
    };

    renderer.domElement.addEventListener('click', handleClick);
    renderer.domElement.addEventListener('mousedown', handleMouseDown);
    renderer.domElement.addEventListener('mouseup', handleMouseUp);

    let keepRendering = true;
    const render = () => {
        controls.update();
        renderer.render(scene, camera);
        cssRenderer.render(cssScene, camera);
        if (keepRendering) {
            requestAnimationFrame(render);
        }
    };
    render();

    return {
        panorama,
        cssScene,
        dispose: () => {
            keepRendering = false;
            window.removeEventListener('resize', handleResize);
            disposeObjectTree(scene, {
                destroyGeometry: true,
                destroyMaterial: true,
                removeFromParent: true,
            });
            renderer.dispose();
            renderer.domElement.removeEventListener('click', handleClick);
            renderer.domElement.removeEventListener('mousedown', handleMouseDown);
            renderer.domElement.removeEventListener('mouseup', handleMouseUp);
            renderer.domElement.remove();
            cssRenderer.domElement.remove();
        },
    };
};

export { setupPanorama };
