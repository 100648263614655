import React from 'react';
import styles from './styles.module.scss';

const PageUnavailable: React.FC = () => (
  <>
    <div className={styles['page-unavailable__heading']}>Page Unavailable</div>
  </>
);

export default PageUnavailable;
