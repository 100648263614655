export enum Tokens {
    LOGIN = 'bi:token',
}

export interface JWT {
    username: string;
    exp: number;
    iat: number;
    id: number;
    role: string;
    email: string;
}
