import { FC, useEffect, useRef } from 'react';
import { Message } from 'src/types/chat-types';
import ChatMessage from './ChatMessage';
import styles from '../Chat.module.scss';

interface Props {
  messages: Message[];
}

const ChatList: FC<Props> = ({ messages }) => {

  const listRef = useRef<any>();

  const scrollList = () => {
    listRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollList();
  }, [messages]);

  return (
    <ul className={styles['chat-list']}>
      {messages.map((message: Message) => (
        <ChatMessage key={message.id} data={message} />
      ))}
      <div ref={listRef} />
    </ul>
  );
};

export default ChatList;
