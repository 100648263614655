import { FC, useContext } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Message } from 'src/types/chat-types';
import AuthContext from 'src/store/auth-context';
import styles from '../Chat.module.scss';
import RemoveMessage from '../components/RemoveMessage';

interface Props {
  data: Message;
}

const ChatMessage: FC<Props> = ({ data }) => {

  dayjs.extend(relativeTime);

  const { user, isLoggedIn } = useContext(AuthContext);

  let mentioned = false;

  if (isLoggedIn) {

    const userRe = new RegExp(`@${user.username}`, 'gmi');

    mentioned = !!data.message.match(userRe);

  }

  const renderBadge = () => {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data?.user?.role === 3 && (
      <span className={styles['chat-badge']}>Moderator</span>
    )
  }

  const htmlRe = new RegExp('<(\\/?|\\!?)(html|head|body|script|div|img|link|h[1-6]).*?>', 'gmi');

  return (
    <>
      <div
        className={[styles['chat-message'], (data.highlighted ? styles['chat-message__highlighted'] : ''), (mentioned ? styles['mentioned'] : '')].join(' ')}>
        <div className={styles['chat-message__meta']}>
          <span className={styles['chat-message__author']}>{data.user.username} {renderBadge()}</span>
          <span className={styles['chat-message__data']}>{dayjs(data.created_at).fromNow()}</span>
          {user.role === 'Moderator' && (
            <RemoveMessage id={data.id} />
          )}
        </div>
        {user.role === 'Moderator' ? (
          <div className={styles['chat-message__body']} dangerouslySetInnerHTML={{ __html: data.message}} />
        ): (
          <div className={styles['chat-message__body']}>{data.message.replace(htmlRe, '')}</div>
        )}
      </div>
    </>
  );
};

export default ChatMessage;
