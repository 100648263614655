import BarButton from '../BarButton/BarButton';
import Nav from '../Nav/Nav';

const Navbar = () => {
  return (
    <>
      <BarButton />
      <Nav />
    </>
  );
};

export default Navbar;
