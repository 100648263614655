import React, { FunctionComponent, useEffect, useState } from 'react';

import { AVAILABLE_MODAL_CONTENT_TYPE, MODAL_NAVIGATION_DIRECTIONS } from 'src/utils/modal';
import ImageContent from './DialogComponentTypes/ImageContent/ImageContent';
import TextContent from './DialogComponentTypes/TextContent/TextContent';
import VideoContent from './DialogComponentTypes/VideoContent/VideoContent';
import AudioContent from './DialogComponentTypes/AudioContent/AudioContent';
import IFrameContent from './DialogComponentTypes/IFrameContent/IFrameContent';
import GalleryContent from './DialogComponentTypes/GalleryContent/GalleryContent';
import { filterContent, findContentIndex, validateIndex } from './DialogComponent.utils';

import styles from './DialogComponent.module.scss';

export interface DataProps {
  id: number;
  type: string;
  adTag: string;
  asset: any;
  creditInfo: string | undefined;
  content?: string | undefined;
  caption: string | undefined;
  name: string | undefined;
  isClickable: boolean;
  additionalSource: AdditionelSource;
  iframe?: string;
}

export interface ContentProps extends DataProps {
  onClose?: () => void;
  handleSlideChange?: (direction: string) => void;
}

interface DialogComponentProps {
  id?: number;
  data?: DataProps[];
  onClose?: () => void;
}

const DialogComponent: FunctionComponent<DialogComponentProps> = ({ onClose, ...rest }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(1);
  const [contentType, setContentType] = useState<string>('');
  const [slideDetails, setSlideDetails] = useState<DataProps>({
    id: 0,
    type: '',
    adTag: '',
    asset: {},
    creditInfo: '',
    content: '',
    caption: '',
    name: '',
    isClickable: false,
    iframe: '',
    additionalSource: {
      url: ''
    }
  });

  useEffect(() => {
    const { id, data } = rest;
    if (data && id) {
      const currentIndex = findContentIndex(data, id);
      const contentDetails = filterContent(data, id);
      setSlideDetails(prev => Object.assign({}, prev, contentDetails));
      setCurrentSlideIndex(currentIndex);
      contentDetails && setContentType(contentDetails?.type);
    }
  }, []);

  const handleSlideChange = (direction: string) => {
    const { data } = rest;
    if (data) {
      const index = direction === MODAL_NAVIGATION_DIRECTIONS.NEXT ? currentSlideIndex + 1 : currentSlideIndex - 1;
      const nextIndex = validateIndex(data, index);
      setCurrentSlideIndex(nextIndex)
      setSlideDetails(prev => Object.assign({}, prev, data[nextIndex]));
      data[nextIndex] && setContentType(data[nextIndex]?.type);
    }
  };

  const matchContentType = (type: string, details: DataProps) => {
    switch (type) {
      case AVAILABLE_MODAL_CONTENT_TYPE.IMAGE:
        return <ImageContent {...details} onClose={onClose} handleSlideChange={handleSlideChange} />;
      case AVAILABLE_MODAL_CONTENT_TYPE.TEXT:
        return <TextContent {...details} onClose={onClose} handleSlideChange={handleSlideChange} />;
      case AVAILABLE_MODAL_CONTENT_TYPE.VIDEO:
        return <VideoContent {...details} onClose={onClose} handleSlideChange={handleSlideChange} />;
      case AVAILABLE_MODAL_CONTENT_TYPE.AUDIO:
        return <AudioContent {...details} />;
      case AVAILABLE_MODAL_CONTENT_TYPE.GALLERY:
        return <VideoContent {...details} onClose={onClose} handleSlideChange={handleSlideChange} />;
      case AVAILABLE_MODAL_CONTENT_TYPE.IFRAME:
        return <IFrameContent {...details} onClose={onClose} handleSlideChange={handleSlideChange} />
      default:
        return false;
    }
  };

  return (
    <div className={styles.Container}>
      {matchContentType(contentType, slideDetails)}
    </div>
  )
}

export { DialogComponent }
