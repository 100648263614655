import { TextureBoxesTypes } from 'src/types/room';
import {
  CubeTextureLoader,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  Sprite,
  Mesh,
  Texture,
} from 'three';
import {ListenersList} from './setupRoom';
import {CSS3DObject} from './vendor/three/CSS3DRenderer';

interface PositionObjectParams {
  object: THREE.Object3D | CSS3DObject;
  x?: number;
  y?: number;
  z?: number;
  rx?: number;
  ry?: number;
  rz?: number;
  scaleFactor?: number;
  mirroredAxis?: string;
  panolensWallLength?: number;
  textureSize?: number;
}

const positionObject = ({
                          object,
                          x,
                          y,
                          z,
                          rx,
                          ry,
                          rz,
                          scaleFactor,
                          panolensWallLength = 10000,
                          textureSize = 2048,
                        }: PositionObjectParams) => {
  const isSprite = object instanceof Sprite;

  const factor = panolensWallLength / textureSize;

  if (!isSprite) {
    object.scale.set(factor, factor, factor);
  }

  object.position.set(x || 0, y || 0, z || 0);
  object.rotation.set(rx || 0, ry || 0, rz || 0);
  object.scale.set(scaleFactor || 1, scaleFactor || 1, 1);
  return object;
};

const isHtmlObjectDefinition = (
  htmlObjectDefinition: HtmlObjectDefinition
): htmlObjectDefinition is HtmlObjectDefinition =>
  typeof htmlObjectDefinition === 'object' &&
  typeof htmlObjectDefinition.htmlString === 'string' &&
  typeof htmlObjectDefinition.width === 'number' &&
  typeof htmlObjectDefinition.height === 'number' &&
  typeof htmlObjectDefinition.position === 'object';

const isMaterialDefinition = (materialDefinition: MaterialDefinition): materialDefinition is MaterialDefinition =>
  typeof materialDefinition === 'object' &&
  typeof materialDefinition.type === 'string' &&
  typeof materialDefinition.name === 'string' &&
  typeof materialDefinition.params === 'object' &&
  (typeof materialDefinition.envMap === 'object' || !materialDefinition.envMap);

const isTextureBoxDefinition = (
  textureBoxDefinition: TextureBoxDefinition
): textureBoxDefinition is TextureBoxDefinition =>
  typeof textureBoxDefinition === 'object' &&
  typeof textureBoxDefinition.asset === 'object' &&
  typeof textureBoxDefinition.name === 'string' &&
  typeof textureBoxDefinition.position === 'object'&&
  textureBoxDefinition.type !== TextureBoxesTypes.SKY_GALLERY;

const isNFTDefinition = (
  textureBoxDefinition: TextureBoxDefinition
): textureBoxDefinition is TextureBoxDefinition =>
  typeof textureBoxDefinition === 'object' &&
  typeof textureBoxDefinition.asset === 'object' &&
  typeof textureBoxDefinition.name === 'string' &&
  typeof textureBoxDefinition.position === 'object' &&
  textureBoxDefinition.type === TextureBoxesTypes.SKY_GALLERY;

const isBendPlaneDefinition = (
  bendPlaneDefinition: BendPlanesDefinition
): bendPlaneDefinition is BendPlanesDefinition =>
  typeof bendPlaneDefinition === 'object' &&
  typeof bendPlaneDefinition.asset === 'object' &&
  typeof bendPlaneDefinition.name === 'string' &&
  typeof bendPlaneDefinition.position === 'object' &&
  typeof bendPlaneDefinition.bend === 'number';

const isPointLightDefinition = (
  pointLightDefinition: PointLightDefinition
): pointLightDefinition is PointLightDefinition =>
  typeof pointLightDefinition === 'object' &&
  (typeof pointLightDefinition.color === 'number' ||
    typeof pointLightDefinition.color === 'string' ||
    !pointLightDefinition.color) &&
  (typeof pointLightDefinition.intensity === 'number' || !pointLightDefinition.intensity) &&
  typeof pointLightDefinition.position === 'object';

const isRoomLinkDefinition = (roomLinkDefinition: RoomLinkDefinition): roomLinkDefinition is RoomLinkDefinition =>
  typeof roomLinkDefinition === 'object' &&
  typeof roomLinkDefinition.position === 'object';

const materialDefinitionToInstance = ({type, name, params, envMap}: MaterialDefinition): MaterialInstance => {
  let materialClass;
  switch (type) {
    case 'physical':
      materialClass = MeshPhysicalMaterial;
      break;
    case 'basic':
      materialClass = MeshBasicMaterial;
      break;
    default:
      materialClass = null;
  }

  if (!materialClass) {
    return {
      name,
      material: new MeshBasicMaterial(),
    };
  }

  if (envMap && envMap.textureUrls) {
    params.envMap = new CubeTextureLoader().load(envMap.textureUrls);
  }

  let material;
  try {
    material = new materialClass(params ?? {});
  } catch {
    material = new MeshBasicMaterial();
  }

  return {
    name,
    material,
  };
};

const disposeMeshes = (meshSet: Set<Mesh | Sprite>) => {
  meshSet.forEach((mesh) => {
    mesh.geometry.dispose();

    if (Array.isArray(mesh.material)) {
      mesh.material.forEach((material) => material.dispose());
    } else {
      mesh.material.dispose();
    }
  });
};

const disposeTextures = (textureSet: Set<Texture>) => {
  textureSet.forEach((texture) => {
    texture.dispose();
  });
};

const disposeListeners = (listenersSet: Set<ListenersList>) => {
  listenersSet.forEach(({object, action, listener}) => {
    object.removeEventListener(action, listener);
  });
};

const createVideoContainer = (node: HTMLDivElement) => {
  const container = document.createElement('div');
  container.id = 'VideoCache';
  container.style.width = '1px';
  container.style.height = '1px';
  container.style.overflow = 'hidden';
  container.style.position = 'absolute';
  container.style.top = '0';

  node.appendChild(container);
  return container;
};

export {
  isHtmlObjectDefinition,
  isMaterialDefinition,
  isPointLightDefinition,
  isRoomLinkDefinition,
  isTextureBoxDefinition,
  isNFTDefinition,
  isBendPlaneDefinition,
  materialDefinitionToInstance,
  disposeMeshes,
  disposeTextures,
  disposeListeners,
  createVideoContainer,
  positionObject,
};
