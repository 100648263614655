import { useContext, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Credentials } from '../../../types/auth-types';
import AuthContext from '../../../store/auth-context';

import useHttp, { RequestStatus } from '../../../hooks/use-http';
import { login } from '../../../api/auth-api';
import LoginForm from './LoginForm';
import { Routes } from 'src/types/routes';

interface Props {
  handleModalRegisterLink?: () => void;
  handleModalResetPasswordLink?: () => void;
}

const LoginFormContainer: FC<Props> = ({ handleModalRegisterLink, handleModalResetPasswordLink }) => {
  const { isLoggedIn, handleLogin, user, handleCloseAuthModal } = useContext(AuthContext);
  const { push } = useHistory();
  const { sendRequest, status, data, error } = useHttp(login);

  useEffect(() => {
    if (status === RequestStatus.completed && data) {
      const userData = {
        id: data.user?.id,
        username: data.user?.username,
        email: data.user?.email,
        role: data.user?.role?.name
      };
      const token = data.jwt;
      handleLogin(userData, token);
      if (handleModalRegisterLink || handleModalResetPasswordLink) {
        handleCloseAuthModal();
      } else {
        push(Routes.MAIN);
      }
    }
  }, [status, data]);

  const onSubmitHandle = (values: Credentials) => {
    return sendRequest(values);
  };

  return (
    <>
      {!isLoggedIn && (
        <LoginForm
          error={error}
          onSubmit={onSubmitHandle}
          handleModalRegisterLink={handleModalRegisterLink}
          handleModalResetPasswordLink={handleModalResetPasswordLink}
        />)
      }
    </>
  );
};

export default LoginFormContainer;
