import { FC } from 'react';

import ResetPasswordForm from './ResetPasswordForm';
import { ResetPasswordFormData } from '../../../types/auth-types';

import useHttp, { RequestStatus } from '../../../hooks/use-http';
import { forgotPassword } from '../../../api/auth-api';
import InfoContainer from 'src/components/common/InfoContainer/InfoContainer';

interface Props {
    handleModalRegisterLink?: () => void;
}

const ResetPassword: FC<Props> = ({ handleModalRegisterLink }) => {
    const { sendRequest, status, error } = useHttp(forgotPassword);

    const onSubmitHandle = (values: ResetPasswordFormData) => {
        return sendRequest(values);
    };

    return (
        <>
            {status === RequestStatus.completed && !error ? (
                <InfoContainer title="Check your inbox">
                    We just emailed you password reset link. If the e-mail hasn't arrived within 5 minutes, please check
                    your spam, bulk or junk mail folder to locate it.
                </InfoContainer>
            ) : (
                <ResetPasswordForm
                    error={error}
                    onSubmit={onSubmitHandle}
                    handleModalRegisterLink={handleModalRegisterLink}
                />
            )}
        </>
    );
};

export default ResetPassword;
