import { FC, useContext, useEffect } from 'react';
import RegisterForm from './RegisterForm';
import { Register as RegisterInterface } from '../../../types/auth-types';

import useHttp, { RequestStatus } from '../../../hooks/use-http';
import { register } from '../../../api/auth-api';
import AuthContext from '../../../store/auth-context';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../types/routes';

interface Props {
  handleModalLoginLink?: () => void;
}

const RegisterFormContainer: FC<Props> = ({ handleModalLoginLink }) => {
  const { handleLogin, isLoggedIn, handleCloseAuthModal } = useContext(AuthContext);
  const { push } = useHistory();
  const { sendRequest, status, error, data } = useHttp(register);

  useEffect(() => {
    if (status === RequestStatus.completed && data) {
      const userData = {
        id: data.user?.id,
        username: data.user?.username,
        email: data.user?.email,
        role: data.user?.role.name,
      };
      const token = data.jwt;
      handleLogin(userData, token);
      if (handleModalLoginLink) {
        handleCloseAuthModal();
      } else {
        push(Routes.MAIN);
      }

    }
  }, [status, data]);

  const onSubmitHandle = (values: RegisterInterface) => {
    return sendRequest(values);
  };

  return (
    <>
      {!isLoggedIn && (
        <RegisterForm
          error={error}
          onSubmit={onSubmitHandle}
          handleModalLoginLink={handleModalLoginLink}
        ></RegisterForm>
      )}
    </>
  );
};

export default RegisterFormContainer;
