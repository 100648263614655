import React, { FC } from 'react';
import classes from './Field.module.scss';

import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface Props {
    input: React.ReactElement;
    labelFor?: string;
    label?: string;
    error?: string;
    touched?: boolean;
}

const Field: FC<Props> = ({ input, label, children, error, touched, labelFor }) => {
    return (
        <div className={classes['field']}>
            {label ? (
                <>
                    <label htmlFor={labelFor} className={classes['field__label']}>
                        <span className={classes['field__label-text']}>{label}</span>
                    </label>
                    {React.cloneElement(input, { labelFor, touched, error })}
                </>
            ) : (
                React.cloneElement(input, { touched, error })
            )}
            {children}
            {error && touched && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    );
};

export default Field;
