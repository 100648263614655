import { MetaDataTypes } from 'src/types/meta-types';
import api from './settings';

export function getMetaData(): Promise<MetaDataTypes> {
    return api
        .get(`/meta`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
            if (error.response?.data?.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error(error.message);
            }
        });
}
