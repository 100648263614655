import { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './styles/reset.scss';

import AuthContext, { StaffRoute } from './store/auth-context';
import MainLayout from './components/layout/MainLayout';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import RecoverPassword from './pages/RecoverPassword';
import Spinner from './components/common/Spinner/Spinner';
import Entrance from './pages/Entrance';
import { RoomContainer } from './components/room/Room.container';
import { Routes } from './types/routes';
import BookmarksPage from './pages/Bookmarks';
import useHttp from './hooks/use-http';
import { getMetaData } from './api/meta-api';
import { Helmet } from 'react-helmet';
import Chat from './pages/Chat';
import Settings from './pages/Settings';
import SkyGallery from "./pages/SkyGallery";

function App() {
  const { storageReady } = useContext(AuthContext);

  const { sendRequest: metaRequest, data: metaData, error: metaError } = useHttp(getMetaData);

  useEffect(
    () => {
      metaRequest();
    }, [],
  );

  return (
    <MainLayout>
      {metaData && !metaError &&
        <Helmet>
          <meta property='og:image' content={metaData.image.url} />
          <meta property='og:description' content={metaData.description} />
          <meta property='og:title' content={metaData.title} />
          <meta name='twitter:description' content={metaData.description} />
          <meta name='twitter:image' content={metaData.image.url} />
          <meta name='twitter:title' content={metaData.title} />
          <title>{metaData.title}</title>
        </Helmet>
      }

      {storageReady ? (
        <Switch>
          <Route path={Routes.MAIN} exact>
            <Entrance />
          </Route>
          <Route path={Routes.LOGIN} exact>
            <Login />
          </Route>
          <Route path={Routes.FORGOT_PASSWORD} exact>
            <ResetPassword />
          </Route>
          <Route path={Routes.REGISTER} exact>
            <Register />
          </Route>
          <Route path={Routes.RECOVER_PASSWORD} exact>
            <RecoverPassword />
          </Route>
          {/* <PrivareRoute path={Routes.SKY_GALLERY} component={SkyGallery} /> */}
          {/* <SkyGallery /> */}
          {/* </Route> */}
          <Route path={Routes.ROOM}>
          <RoomContainer />
          </Route>
          <Route path={Routes.BOOKMARKS}>
            <BookmarksPage />
          </Route>
          <Route path={Routes.CHAT}>
            <Chat />
          </Route>
          <StaffRoute path={Routes.SETTINGS}>
            <Settings />
          </StaffRoute>
          <Route path={Routes.VALENTINO}>
            <Redirect to={`/room/33`} />
          </Route>
          <Route path={Routes.FERRAGAMO}>
            <Redirect to={`/room/25`} />
          </Route>
          <Route path={Routes.DIOR}>
            <Redirect to={`/room/28`} />
          </Route>
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      ) : (
        <Spinner height={80} thickness={10} />
      )}
    </MainLayout>
  );
}

export default App;
