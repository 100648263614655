import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import DialogDefaultNavigation from '../../../DialogDefaultNavigation/DialogDefaultNavigation';
import { ContentProps } from '../../DialogComponent';

import styles from './TextContent.module.scss';

const TextContent: FunctionComponent<ContentProps> = ({
  caption = '',
  onClose,
  handleSlideChange
}) => (
  <div className={styles.Container}>
    <div className={styles.TextContainer}>
      <div className={styles.TextWrapper}>
        <div className={styles.ContentWrapper}>
          <ReactMarkdown
            children={caption}
            linkTarget='_blank'
          />
        </div>
        <div className={styles.mask} />
      </div>
      <DialogDefaultNavigation handleSlideChange={handleSlideChange} onClose={onClose} />
    </div>
  </div>
);

export default TextContent;
