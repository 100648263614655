import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRooms } from 'src/api/room-api';
import { getNFTs } from 'src/api/nft-api';
import { getBookmarks } from 'src/api/bookmarks-api';
import useHttp from 'src/hooks/use-http';
import AuthContext from 'src/store/auth-context';
import { NavContext } from 'src/store/nav-context';
import { Room } from 'src/types/room';
import { NFTsDetailsProps } from 'src/types/nft.types';
import { Routes } from 'src/types/routes';
import NavAccordion from '../NavAccordion/NavAccordion';
import NFTAccordion from '../NFTAccordion/NFTAccordion';
import classes from './Nav.module.scss';
import { Bookmark } from 'src/types/bookmark';
import { ReactComponent as ArrowDown } from 'src/assets/icons/arrowDown.svg';

const MENU_TYPES = {
  ROOMS: 'ROOMS',
  NFTS: 'NFTS'
};

const Nav: React.FC = () => {
  const [nfts, setNfts] = useState<NFTsDetailsProps[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const { sendRequest: roomsRequest, data: roomsData, error: roomsError } = useHttp(getRooms);
  const { sendRequest: nftsRequest, data: nftsData, error: nftsError } = useHttp(getNFTs);
  const { open } = useContext(NavContext);
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState<string>(MENU_TYPES.ROOMS);

  const toggleMenuVisibility = (menuType: string) => {
    return setIsOpen(prev => prev === menuType ? '' : menuType);
  };

  useEffect(() => {
    roomsRequest();
    nftsRequest();
  }, []);

  useEffect(() => {
    if (roomsData && !roomsError) {
      setRooms(roomsData);
    }
  }, [roomsData, roomsError]);

  useEffect(() => {
    if (nftsData && !nftsError) {
      const { NFTsDetails } = nftsData;
      setNfts(NFTsDetails);
    }
  }, [nftsData, nftsError]);

  const navClasses = `${classes.nav} ${open ? classes['nav--active'] : ''}`;

  return (
    <nav className={navClasses}>
      <h3 className={`${classes.heading} ${isOpen === MENU_TYPES.ROOMS && classes.open}`} onClick={() => toggleMenuVisibility(MENU_TYPES.ROOMS)}>The Rooms <ArrowDown /></h3>
      {rooms && isOpen === MENU_TYPES.ROOMS && <NavAccordion items={rooms} />}

      {isLoggedIn && (
        <>
          <h3 className={classes.heading}>User profile</h3>
          <Link to={Routes.BOOKMARKS}>Bookmarks</Link>
          <button onClick={handleLogout}>Logout</button>
        </>
      )}

      {/*<h3 className={`${classes.heading} ${isOpen === MENU_TYPES.NFTS && classes.open}`} onClick={() => toggleMenuVisibility(MENU_TYPES.NFTS)}>THE NFT<span>s</span> <ArrowDown /></h3>*/}
      {/*{nfts && isOpen === MENU_TYPES.NFTS && <NFTAccordion items={nfts} />}*/}

      {/* <ol className={classes.legalLinksList}>
        <li>Legal link 1</li>
        <li>Legal link 2</li>
        <li>Legal link 3</li>
      </ol> */}
    </nav>
  );
};

export default Nav;
