import { useContext } from 'react';
import Hamburger from 'hamburger-react'
import { NavContext } from 'src/store/nav-context';
import classes from './BarButton.module.scss';

const BarButton: React.FC = () => {
  const { handleToggle, open } = useContext(NavContext);

  return (
    <div className={classes['bar-button']} onClick={handleToggle}>
      <Hamburger toggled={open} size={15} color="#ffffff" />
    </div>
  );
};

export default BarButton;
