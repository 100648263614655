import { FC } from 'react';
import classes from './ErrorMessage.module.scss';

interface Props {
    cssClass?: string;
}

const ErrorMessage: FC<Props> = ({ children, cssClass = '' }) => (
    <p className={`${classes.error} ${cssClass}`}>{children}</p>
);

export default ErrorMessage;
