import { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import classes from './RegisterForm.module.scss';
import fieldClasses from '../../common/Field/Field.module.scss';
import checkboxClasses from '../../common/Field/Checkbox.module.scss';

import { Register } from 'src/types/auth-types';
import Button from '../../common/Button/Button';
import Form from '../../common/Form/Form';
import Spinner from '../../common/Spinner/Spinner';
import Field from '../../common/Field/Field';
import Input from '../../common/Input/Input';
import InputPassword from '../../common/InputPassword/InputPassword';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import {
  usernameValidators,
  emailValidators,
  passwordValidators,
  marketingValidators,
  dataProcessingValidators,
} from 'src/utils/validators';
import Checkbox from '../../common/Checkbox/Checkbox';
import ForwardLink, { Variant, Props as ForwardLinkProps } from '../../common/Link/ForwardLink';
import { Routes } from 'src/types/routes';

interface Props {
  error: string | string[] | null;
  onSubmit: (values: Register) => Promise<void>;
  handleModalLoginLink?: () => void;
}

const RegisterForm: FC<Props> = ({ error, onSubmit, handleModalLoginLink }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const RegisterSchema = Yup.object().shape({
    username: usernameValidators,
    email: emailValidators,
    password: passwordValidators,
    marketing_consent: marketingValidators,
    data_processing_consent: dataProcessingValidators,
  });

  const { values, errors, touched, isSubmitting, handleChange, handleSubmit, getFieldProps } = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      marketing_consent: false,
      data_processing_consent: false,
    },
    onSubmit: onSubmit,
    validationSchema: RegisterSchema,
  });

  const changePasswordShow = () => setShowPassword((state) => !state);

  const signInLink: ForwardLinkProps = {
    description: '- or -',
    ...(handleModalLoginLink
      ? { variant: Variant.BUTTON, onClick: handleModalLoginLink, centered: true }
      : { variant: Variant.LINK, to: Routes.LOGIN, centered: true }),
  };

  return (
    <Form onSubmit={handleSubmit} title='Register'>
      <Field
        label='Full Name*'
        input={<Input
          // placeholder="Enter your username"
          type='text'
          {...getFieldProps('username')}
        />}
        error={errors.username}
        touched={touched.username}
      />
      <Field
        label='Email*'
        input={<Input
          // placeholder="Enter email address"
          type='text'
          {...getFieldProps('email')}
        />}
        error={errors.email}
        touched={touched.email}
      />
      <Field
        label='Password*'
        input={
          <InputPassword
            {...getFieldProps('password')}
            showPassword={showPassword}
            onShowPassword={changePasswordShow}
          />
        }
        error={errors.password}
        touched={touched.password}
      ></Field>
      <label className={classes['register-form__label']}>
        <Checkbox name='data_processing_consent' onChange={handleChange} checked={values.data_processing_consent} />
        <span className={checkboxClasses['form-check__label']}>
                    I hereby give my consent to the processing of my personal data for the purposes of <a
          target={`_blank`} href='https://www.iubenda.com/privacy-policy/62528785/legal'>Privacy Policy</a>.
                </span>
      </label>
      {errors.data_processing_consent && touched.data_processing_consent && (
        <ErrorMessage>{errors.data_processing_consent}</ErrorMessage>
      )}
      <label className={classes['register-form__label']}>
        <Checkbox name='marketing_consent' onChange={handleChange} checked={values.marketing_consent} />
        <span className={checkboxClasses['form-check__label']}>
          I agree to receive marketing messages about L’Officiel in accordance with the <a target={`_blank`}
                                                                                           href='https://www.iubenda.com/privacy-policy/62528785/legal'>Privacy Policy</a>.
        </span>
      </label>
      {errors.marketing_consent && touched.marketing_consent && (
        <ErrorMessage>{errors.marketing_consent}</ErrorMessage>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {isSubmitting ? (
        <Spinner height={40} thickness={10} />
      ) : (
        <Button cssClass={classes['register-form__submit']} type='submit'>
          Register
        </Button>
      )}

      <ForwardLink {...signInLink}>Log In</ForwardLink>
    </Form>
  );
};

export default RegisterForm;
