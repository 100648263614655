import api from './settings';
import { AxiosResponse } from 'axios';

export function getBookmarks() {
  return api.get('/bookmarks')
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function createBookmark(room: string) {
  return api.post('/bookmarks', {
    room: room,
  })
    .then((res: AxiosResponse<any>) => {
      return {
        type: 'create',
        bookmark: res.data,
      };
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteBookmark(room: string) {
  return api.delete(`/bookmarks/${room}`)
    .then((res: AxiosResponse<any>) => {
      return {
        type: 'delete',
        bookmark: res.data,
      };
    })
    .catch(error => {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function toggleBookmark(room: string, isBookmarked: boolean) {
  return isBookmarked ? deleteBookmark(room) : createBookmark(room);
}
